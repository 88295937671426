import React from "react"
import styled from "styled-components"
import { Container } from "reactstrap"

import BannerBg from "../images/banner-marketing.svg"
import ImageKampane from "../images/marketing/kampane.png"
import ImageDataAnalytica from "../images/marketing/datova-analytica.png"
import ImageVyzkum from "../images/marketing/vyzkum.png"

const BannerW = styled.div`
  align-items: center;
  display: flex;
  padding: 42px 0 62px;
  background-image: linear-gradient(-27deg, #e87722 0%, #ffb41b 100%);
  text-align: center;

  .container {
    max-width: 760px;
  }

  h1 {
    font-size: 24px;
    color: ${(props) => props.theme.color.white};
  }

  p {
    color: ${(props) => props.theme.color.white};
    margin-bottom: 50px;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    height: 500px;
    background: url(${BannerBg}) center no-repeat;
    padding: 180px 0 120px;

    h1 {
      font-size: 36px;
      margin-bottom: 20px;
      width: 500px;
    }

    p {
      width: 450px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 120px;
    }
  }

  @media (min-width: ${(props) => props.theme.sizes_min.xl}) {
    height: 600px;
    padding: 150px 0 120px;

    h1 {
      font-size: 42px;
      margin-bottom: 25px;
      width: 600px;
    }

    p {
      width: 500px;
      margin-bottom: 100px;
    }
  }

  @media (min-width: ${(props) => props.theme.sizes_min.xxl}) {
    height: 800px;

    h1 {
      font-size: 56px;
      margin-bottom: 30px;
      width: 100%;
    }

    p {
      margin-bottom: 50px;
      width: 90%;
    }
  }
`

const ImageWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    display: none;
  }
`

const Banner = (props) => (
  <BannerW>
    <Container>
      <h1>{props.title}</h1>
      {props.children}

      <ImageWrapper className={"mt-4"}>
        <img
          src={ImageVyzkum}
          loading={"eager"}
          alt={"Výzkumy obrázek"}
          className={"img-fluid"}
        />
      </ImageWrapper>

      <ImageWrapper>
        <img
          src={ImageKampane}
          loading={"eager"}
          alt={"Kampaně obrázek"}
          className={"img-fluid"}
        />
      </ImageWrapper>

      <ImageWrapper className={"mt-3"}>
        <img
          src={ImageDataAnalytica}
          loading={"eager"}
          alt={"Datová analytica obrázek"}
          className={"img-fluid"}
        />
      </ImageWrapper>
    </Container>
  </BannerW>
)

export default Banner
