import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  padding: 25px 0;
`

const PaddingHalfWrapper = (props) => <Wrapper>{props.children}</Wrapper>

export default PaddingHalfWrapper
