import React from "react"
import styled from "styled-components"

const Title = styled.h2`
  display: inline-block;
  text-align: left;
`

const TitleH2 = (props) => (
  <div>
    <Title id={props.id} className={props.className}>
      {props.children}
    </Title>
  </div>
)

export default TitleH2
