import React from "react"
import styled from "styled-components"

import { Col, Container, NavLink, Row, TabContent, TabPane } from "reactstrap"

import SquareBorder from "./square-border/square-border"
import classnames from "classnames"
import { Link } from "gatsby"

const SquareBorderCustom = styled(SquareBorder)``

const FullStoryLink = styled.div`
  margin-top: 2em;
  &:before {
    content: "⇢ ";
  }
`

const NavLinkCustom = styled(NavLink)`
  padding: 0;

  ${SquareBorderCustom} {
    margin-bottom: 30px;
  }

  @media (max-width: ${(props) => props.theme.sizes_max.sm}) {
    ${SquareBorderCustom} {
      padding: 0;

      &:after {
        border: none;
        border-bottom: 3px solid transparent;
      }

      img {
        filter: grayscale(1);
      }
    }
  }

  &.active {
    ${SquareBorderCustom} {
      &:after {
        border-bottom: 3px solid ${(props) => props.theme.color.primary};
      }

      img {
        filter: grayscale(0);
      }
    }
  }

  @media (min-width: ${(props) => props.theme.sizes_min.sm}) {
    &.active,
    &:hover,
    &:focus {
      ${SquareBorderCustom} {
        &:after {
          border: 3px solid ${(props) => props.theme.color.primary};
        }
      }
    }
  }
`

const CustomersWrapper = styled.div`
  padding: 70px 0;
`

const CustomersName = styled.div`
  font-size: 20px;
  font-weight: 600;
`

const CustomersPosition = styled.div`
  font-size: 14px;
  font-weight: 500;
`

const CustomersNameColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    flex-direction: row;
    text-align: left;
  }
`

const CustomersImage = styled.img`
  border-radius: 50%;
  margin-right: 10px;
  width: 80px;
  height: 80px;
`

const CustomersLink = styled(Link)`
  display: inline-block;
  margin-top: 5px;
`

const CustomersNameWrapper = (props) => (
  <CustomersNameColumn>
    <CustomersImage src={props.imagePerson} alt="" />
    <div>
      <CustomersName>{props.name}</CustomersName>
      <CustomersPosition>{props.position}</CustomersPosition>
    </div>
  </CustomersNameColumn>
)

export default class ReferencesUsers extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: 1,
    }

    this.title = props.title
    this.data = props.data
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  render() {
    return (
      <CustomersWrapper>
        <Container>
          <h2 className={"h1"}>{this.title}</h2>

          <Row className={"align-items-center"}>
            <Col lg={5} md={6}>
              <Row>
                {this.data.map((reference) => {
                  return (
                    <Col key={reference.id} md={6} xs>
                      <NavLinkCustom
                        className={classnames({
                          active: this.state.activeTab === reference.id,
                        })}
                        onClick={() => {
                          this.toggle(reference.id)
                        }}
                      >
                        <SquareBorderCustom>
                          <img
                            src={reference.imageSrc}
                            alt={reference.imageAlt}
                            width="80"
                            height="80"
                            loading={"lazy"}
                          />
                        </SquareBorderCustom>
                      </NavLinkCustom>
                    </Col>
                  )
                })}
              </Row>
            </Col>

            <Col>
              <TabContent activeTab={this.state.activeTab}>
                {this.data.map((reference) => {
                  return (
                    <TabPane tabId={reference.id} key={reference.id}>
                      <p>{reference.text}</p>
                      <CustomersNameWrapper
                        name={reference.name}
                        position={reference.position}
                        imagePerson={reference.imagePerson}
                      />

                      <FullStoryLink>
                        podívejte se{" "}
                        <CustomersLink to={reference.linkHref}>
                          {reference.linkTitle}
                        </CustomersLink>
                      </FullStoryLink>
                    </TabPane>
                  )
                })}
              </TabContent>
            </Col>
          </Row>
        </Container>
      </CustomersWrapper>
    )
  }
}
