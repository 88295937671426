import React from "react"

const suffix = (years: number): string => {
  if (years === 1) {
    return "rok"
  } else if (years < 5) {
    return "roky"
  } else {
    return "let"
  }
}

const Already = ({ year }: { year: number }): JSX.Element => (
  <>{already(year)}</>
)

const already = (year: number): string => {
  const years = new Date().getFullYear() - year
  return `${years} ${suffix(years)}`
}

export default Already
export { already }
