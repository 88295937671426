import React from "react"
import { Link as GatsbyLink } from "gatsby"

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink

type LinkProps = {
  /** A class to apply when this Link is active */
  activeClassName?: string
  /** Inline styles for when this Link is active */
  activeStyle?: Record<string, unknown>
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  /** Class the link as highlighted if there is a partial match via a the `to` being prefixed to the current url */
  partiallyActive?: boolean
  /** Used to declare that this link replaces the current URL in history with the target */
  replace?: boolean
  /** Used to pass state data to the linked page.
   * The linked page will have a `location` prop containing a nested `state` object structure containing the passed data.
   */
  /** The URL you want to link to */
  to: string
}

const Link: React.FC<LinkProps> = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  ...other
}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link ((intended for Gatsby))
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  )
}
export default Link
