import styled from "styled-components"

const Quote = styled.div`
  position: relative;
  padding: 2rem;
  margin-bottom: 1rem;
  text-align: center;

  &:before,
  &:after {
    font-weight: 700;
    font-size: 3rem;
    color: ${(props) => props.theme.color.gray500};
    position: absolute;
  }
  &:before {
    content: "“";
    top: -1rem;
    left: 0.5rem;
  }

  &:after {
    content: "„";
    bottom: -1rem;
    right: 0.5rem;
  }

  p {
    padding: 0;
    margin: 0;
    text-align: center;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    padding: 3rem;
    &:before {
      top: 1rem;
      left: 0;
    }

    &:after {
      bottom: 1.75rem;
      right: 0;
    }
  }
`

export default Quote
