import React from "react"
import styled from "styled-components"
import SquareBorder from "./square-border/square-border"

const Wrapper = styled.div`
  @media only screen and (min-width: ${(props) => props.theme.sizes_min.lg}) {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 38px;
  }
`
const SquareBorderCustom = styled(SquareBorder)`
  margin: -45px auto 0;

  height: 90px;
  width: 90px;
  flex: none;

  @media only screen and (min-width: ${(props) => props.theme.sizes_min.sm}) {
    margin: -58px auto 0;
    height: 116px;
    width: 116px;
  }

  @media only screen and (min-width: ${(props) => props.theme.sizes_min.lg}) {
    margin: 0 35px 0 0;
    height: 100px;
    width: 100px;
  }

  @media only screen and (min-width: ${(props) => props.theme.sizes_min.xl}) {
    height: 116px;
    width: 116px;
  }
`

const Title = styled.h3`
  font-size: 22px;
  margin: 25px 0 35px;
  text-align: center;

  @media only screen and (min-width: ${(props) => props.theme.sizes_min.lg}) {
    margin: 0;
    text-align: left;
  }

  @media only screen and (min-width: ${(props) => props.theme.sizes_min.xl}) {
    font-size: 32px;
  }
`

const LogoHeading = (props) => (
  <Wrapper className={props.imagePath ? "" : "d-none"}>
    <SquareBorderCustom {...props}>
      <img
        src={props.imagePath}
        srcSet={props.imageSrcSet}
        alt={props.imageAlt}
      />
    </SquareBorderCustom>
    <Title>{props.children}</Title>
  </Wrapper>
)

export default LogoHeading
