const IgloonetTheme = {
  color: {
    //primary orange
    primary: "#e87722",
    primary_hover: "#df6d17",

    //secondary blue
    blue100: "#d1d9e9",
    blue300: "#777f97",
    blue: "#0f1637",

    //gray
    gray100: "#fbfcfd",
    gray200: "#f6f7f9",
    gray300: "#e8e9eb",
    gray500: "#9ea2a7",
    gray700: "#66696d",
    gray900: "#383b3f",

    //others color
    red: "#ea0a0a",
    green: "#15bb5a",
    white: "#fff",
    black: "#000",
  },

  sizes_max: {
    xs: "0",
    sm: "575px",
    md: "767px",
    lg: "991px",
    xl: "1199px",
  },

  sizes_min: {
    xs: "0",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1440px",
  },
}

export default IgloonetTheme
