import React from "react"
import styled from "styled-components"

const Wrapper = styled.table`
  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    width: 50%;
  }
`

const Table = (props) => (
  <Wrapper>
    <tbody>
      <tr>
        <td>Account number:</td>
        <td>{props.ACnumber}</td>
      </tr>
      <tr>
        <td>IBAN</td>
        <td>{props.IBANnumber}</td>
      </tr>
      <tr>
        <td>Account name</td>
        <td>igloonet, s.r.o.</td>
      </tr>
      <tr>
        <td>Recipient address:</td>
        <td>Karasek 5, Brno, 62100</td>
      </tr>
      <tr>
        <td>Bank address:</td>
        <td>Fio banka, a.s., V Celnici 1028/10, Praha 1</td>
      </tr>
      <tr>
        <td>BIC/SWIFT:</td>
        <td>FIOBCZPPXXX</td>
      </tr>
    </tbody>
  </Wrapper>
)

export default Table
