import React from "react"
import styled from "styled-components"

const Text = styled.div`
  color: ${(props) => props.theme.color.primary};
  font-weight: 700;
  padding-top: 25px;
  margin-bottom: 25px;

  border-top: 1px solid ${(props) => props.theme.color.gray300};

  @media only screen and (min-width: ${(props) => props.theme.sizes_min.lg}) {
    max-width: 380px;
  }
`

const Hashtags = (props) => <Text>{props.children}</Text>

export default Hashtags
