import React from "react"

const TextOOU: React.FC = () => (
  <>
    <h2>I. Základní ustanovení</h2>
    <ol>
      <li>
        Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského parlamentu
        a Rady (EU) 2016/679 o&nbsp;ochraně fyzických osob v&nbsp;souvislosti se
        zpracováním osobních údajů a o&nbsp;volném pohybu těchto údajů (dále
        jen: „GDPR”) je společnost igloonet, s.r.o., IČ&nbsp;27713482, se sídlem
        Karásek 5, 621 00 Brno (dále jen: „Správce”).
      </li>
      <li>
        Kontaktní údaje Správce jsou:
        <ul>
          <li>Adresa: igloonet, s.r.o., Karásek 5, 621 00 Brno</li>
          <li>Email: info@igloonet.cz</li>
          <li>Telefon: +420 739 450 400</li>
        </ul>
      </li>
      <li>
        Osobními údaji se rozumí veškeré informace o&nbsp;identifikované nebo
        identifikovatelné fyzické osobě; identifikovatelnou fyzickou osobou je
        fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména
        odkazem na určitý identifikátor, například jméno, identifikační číslo,
        lokační údaje, síťový identifikátor nebo na jeden či více zvláštních
        prvků fyzické, fyziologické, genetické, psychické, ekonomické, kulturní
        nebo společenské identity této fyzické osoby.
      </li>
      <li>Správce nejmenoval pověřence pro ochranu osobních údajů.</li>
    </ol>

    <h2>II. Zdroje a kategorie zpracovávaných osobních údajů</h2>
    <ol>
      <li>
        Správce zpracovává osobní údaje, které jste mu poskytl/a nebo osobní
        údaje, které správce získal na základě plnění Vaší objednávky.
      </li>
      <li>
        Správce zpracovává Vaše identifikační a kontaktní údaje a údaje nezbytné
        pro plnění smlouvy, včetně elektronického souboru &quot;cookie&quot;.
      </li>
      <li>
        Pokud jste se přihlásil k&nbsp;odběru našeho newsletteru, Správce pak
        zpracovává Vaši emailovou adresu a časový záznam, kdy k&nbsp;souhlasu se
        zasíláním komerčních nabídek došlo.
      </li>
    </ol>

    <h2>III. Zákonný důvod a účel zpracování osobních údajů</h2>
    <ol>
      <li>
        Zákonným důvodem zpracování osobních údajů je
        <ul>
          <li>
            plnění smlouvy mezi Vámi a Správcem podle čl. 6 odst. 1 písm. b)
            GDPR,
          </li>
          <li>
            oprávněný zájem Správce na poskytování přímého marketingu (zejména
            pro zasílání obchodních sdělení a newsletterů) podle čl. 6 odst. 1
            písm. f) GDPR,
          </li>
          <li>
            oprávněný zájem Správce spočívající v&nbsp;komunikacim
            s&nbsp;potenciálním zákazníkem/návštěvníkem stránky po vyplnění
            kontaktního formuláře,
          </li>
          <li>
            váš souhlas se zpracováním pro účely poskytování přímého marketingu
            (zejména pro zasílání obchodních sdělení a newsletterů) podle čl. 6
            odst. 1 písm. a) GDPR ve spojení s&nbsp;§ 7 odst. 2 zákona č.
            480/2004 Sb., o&nbsp;některých službách informační společnosti
            v&nbsp;případě, že nedošlo k&nbsp;objednávce zboží nebo služby.
          </li>
        </ul>
      </li>
      <li>
        Účelem zpracování osobních údajů je
        <ul>
          <li>
            vyřízení vaší objednávky a výkon práv a povinností vyplývajících ze
            smluvního vztahu mezi vámi a Správcem; při objednávce jsou
            vyžadovány osobní údaje, které jsou nutné pro úspěšné vyřízení
            objednávky, poskytnutí osobních údajů je nutným požadavkem pro
            uzavření a plnění smlouvy, bez poskytnutí osobních údajů není možné
            smlouvu uzavřít či jí ze strany Správce plnit,
          </li>
          <li>
            zasílání obchodních sdělení a činění dalších marketingových aktivit,
          </li>
          <li>statistické a analytické zpracování návštěvnosti.</li>
        </ul>
      </li>
      <li>
        Ze strany správce dochází k&nbsp;automatickému individuálnímu
        rozhodování ve smyslu čl. 22 GDPR. S&nbsp;takovým zpracováním jste
        poskytl/a svůj výslovný souhlas.
      </li>
    </ol>

    <h2>IV. Doba uchovávání osobních údajů</h2>
    <ol>
      <li>
        Správce uchovává osobní údaje
        <ul>
          <li>
            po dobu nezbytnou k&nbsp;výkonu práv a povinností vyplývajících ze
            smluvního vztahu mezi vámi a správcem a uplatňování nároků
            z&nbsp;těchto smluvních vztahů (po dobu 10 let od ukončení smluvního
            vztahu).
          </li>
          <li>
            po dobu, než je odvolán souhlas se zpracováním osobních údajů pro
            účely marketingu, nejdéle 10 let, jsou-li osobní údaje zpracovávány
            na základě souhlasu.
          </li>
        </ul>
      </li>
      <li>
        Po uplynutí doby uchovávání osobních údajů Správce osobní údaje vymaže.
      </li>
    </ol>

    <h2>V. Příjemci osobních údajů</h2>
    <ol>
      <li>
        Příjemci osobních údajů jsou osoby
        <ul>
          <li>
            podílející se na dodání služeb a realizaci plateb na základě
            smlouvy,
          </li>
          <li>zajišťující provoz webových stránek a související služby,</li>
          <li>zajišťující marketingové služby.</li>
        </ul>
      </li>
      <li>
        Správce má v&nbsp;úmyslu předat osobní údaje do zemí mimo EU) nebo
        mezinárodní organizaci. Příjemci osobních údajů ve třetích zemích jsou
        poskytovatelé mailingových služeb / cloudových služeb.
      </li>
      <li>
        Vedle již uvedených osobních údajů se výše uvedený souhlas vztahuje
        rovněž na data vzniklá při procházení stránek webového rozhraní
        generovaná pomocí statistických a analytických nástrojů (Google
        Analytics, Google Ads, Facebook, LinkedIn, OneSignal, Twitter,
        Albacross). Tato remarketingová data ukládáme maximálně po dobu 365 dní.
        Pomocí těchto online nástrojů nesbíráme vaše osobní data, jako je jméno,
        adresa, e-mail, ani telefon. Záznam pro cílení remarketingových reklam
        můžete odstranit po vymazání souborů cookie ve vašem prohlížeči.
      </li>
    </ol>

    <h2>VI. Vaše práva</h2>
    <ol>
      <li>
        Za podmínek stanovených v&nbsp;GDPR máte
        <ul>
          <li>právo na přístup ke svým osobním údajům dle čl. 15 GDPR,</li>
          <li>
            právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení
            zpracování dle čl. 18 GDPR.
          </li>
          <li>právo na výmaz osobních údajů dle čl. 17 GDPR.</li>
          <li>právo vznést námitku proti zpracování dle čl. 21 GDPR a</li>
          <li>právo na přenositelnost údajů dle čl. 20 GDPR.</li>
          <li>
            právo odvolat souhlas se zpracováním písemně nebo elektronicky na
            adresu nebo email Správce uvedený v&nbsp;čl. I&nbsp;těchto podmínek.
          </li>
        </ul>
      </li>
      <li>
        Dále máte právo podat stížnost u&nbsp;Úřadu pro ochranu osobních údajů
        v&nbsp;případě, že se domníváte, že bylo porušeno vaše právo na ochranu
        osobních údajů.
      </li>
    </ol>

    <h2>VII. Podmínky zabezpečení osobních údajů</h2>
    <ol>
      <li>
        Správce prohlašuje, že přijal veškerá vhodná technická a organizační
        opatření k&nbsp;zabezpečení osobních údajů.
      </li>
      <li>
        Správce přijal technická opatření k&nbsp;zabezpečení datových úložišť a
        úložišť osobních údajů v&nbsp;listinné podobě, zejména zabezpečení
        fyzických dokumentů, technické zabezpečení datových úložišť, šifrování,
        antivirové programy, zálohy a další.
      </li>
      <li>
        Správce prohlašuje, že k&nbsp;osobním údajům mají přístup pouze jím
        pověřené osoby.
      </li>
    </ol>

    <h2>VIII. Závěrečná ustanovení</h2>
    <ol>
      <li>
        Odesláním objednávky z&nbsp;internetového objednávkového formuláře
        potvrzujete, že jste seznámen/a s&nbsp;podmínkami ochrany osobních údajů
        a že je v&nbsp;celém rozsahu přijímáte.
      </li>
      <li>
        S&nbsp;těmito podmínkami souhlasíte také v&nbsp;případě, že potvrdíte
        Správci souhlas v&nbsp;přijatém double opt-in emailu. Tímto potvrzujete,
        že jste seznámen/a s&nbsp;podmínkami ochrany osobních údajů a že je
        v&nbsp;celém rozsahu přijímáte.
      </li>
      <li>
        Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany
        osobních údajů zveřejní na svých internetových stránkách a zároveň Vám
        zašle novou verzi těchto podmínek Vaši e-mailovou adresu, kterou jste
        správci poskytl/a.
      </li>
    </ol>
    <p>Tyto podmínky nabývají účinnosti dnem 25. 5. 2018.</p>
  </>
)

export default TextOOU
