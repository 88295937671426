import styled from "styled-components"
import { NavLink } from "reactstrap"
import Button from "./buttons/button"

const NavLinkCustom = styled(NavLink)`
  display: block;
  color: ${(props) => props.theme.color.gray900};
  font-size: 14px;
  font-weight: 600;

  &__icon {
    margin-right: 10px;
  }

  &__text {
    vertical-align: middle;
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    color: ${(props) => props.theme.color.primary};
    text-decoration: none;
  }
`

const CustomButton = styled(Button)`
  padding: 10px 24px;
  font-size: 14px;
  border: 1px solid ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.primary};

  @media (max-width: ${(props) => props.theme.sizes_max.sm}) {
    padding: 10px 12px;
  }
`
export { NavLinkCustom, CustomButton }
