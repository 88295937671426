import React from "react"
import styled from "styled-components"

const DeviceWrapper = styled.div`
  height: 424px;
  width: 728px;
`
const DeviceFrame = styled.div`
  background: #0d0d0d;
  border-radius: 12px;
  box-shadow: inset 0 0 0 2px #c8c8c8;
  height: 408px;
  margin: 0 auto;
  padding: 24px 22px;
  position: relative;
  width: 584px;
  overflow: hidden;
`

const WrapperMarquee = styled.div`
  overflow: hidden;
  position: relative;
`

const DeviceContent = styled.img`
  border: 2px solid #121212;
  border-radius: 2px;
  overflow: hidden;
  background-size: 100%;
  width: 100%;
  height: auto;
  background-repeat: repeat-y;
`

const DeviceBtns = styled.div`
  &:after,
  &:before {
    background: #c8c8c8;
    content: "";
    height: 2px;
    position: absolute;
    top: -2px;
  }

  &:after {
    left: 15rem;
    width: 26px;
  }
  &:before {
    left: 19rem;
    width: 48px;
  }
`

const DevicePower = styled.div`
  background: linear-gradient(to bottom, #eeeeee, #c8c8c8);
  border: solid #c8c8c8;
  border-radius: 2px;
  border-width: 0 2px;
  height: 12px;
  margin-top: 4px;
  position: relative;
  width: 728px;

  &:after,
  &:before {
    content: "";
    position: absolute;
  }
  &:after {
    background: radial-gradient(
      circle at center,
      #eeeeee 0,
      #eeeeee 95%,
      #75757a 100%
    );
    border-radius: 0 0 6px 6px;
    height: 8px;
    left: 50%;
    margin-left: -125px;
    top: 0;
    width: 250px;
    z-index: 1;
  }
  &:before {
    background: linear-gradient(to bottom, #eeeeee, #c8c8c8);
    border-radius: 2px 2px 0 0;
    bottom: 12px;
    height: 8px;
    left: 50%;
    margin-left: -292px;
    width: 584px;
  }
`

export type LaptopProps = {
  backgroundSrc: string
  backgroundAlt: string
}

const Laptop: React.FC<LaptopProps> = ({ backgroundSrc, backgroundAlt }) => {
  return (
    <DeviceWrapper>
      <DeviceFrame>
        <WrapperMarquee>
          <DeviceContent src={backgroundSrc} alt={backgroundAlt} />
        </WrapperMarquee>
      </DeviceFrame>
      <DeviceBtns />
      <DevicePower />
    </DeviceWrapper>
  )
}

export default Laptop
