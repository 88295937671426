import React from "react"
import styled from "styled-components"
import ReferenceContainer from "../reference/reference-container"

const Wrapper = styled.div`
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 10rem;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    background-size: cover;
  }

  Col {
    display: flex;
    align-self: center;
  }

  h1 {
    margin-bottom: 30px;
    color: ${(props) => props.theme.color.white};
  }

  p {
    color: ${(props) => props.theme.color.white};
    width: 100%;
    margin: auto;
    padding-top: 2rem;
    text-shadow: 0 0 20px ${(props) => props.theme.color.black};

    @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
      width: 50%;
    }
  }

  .logo {
    color: ${(props) => props.theme.color.gray500};

    img {
      max-height: 120px;
    }

    &:hover {
      text-decoration: none;
    }
  }
`

type ReferenceBannerProps = {
  styles?: React.CSSProperties
  extodkaz?: string
  logo: string
  changecolh?: React.CSSProperties
  heading: string
  odstavec?: string
  changecol?: React.CSSProperties
}

const ReferenceBanner: React.FC<ReferenceBannerProps> = ({
  styles,
  extodkaz,
  logo,
  changecolh,
  heading,
  odstavec,
  changecol,
  children,
}) => {
  const logoImage = (
    <img src={logo} alt="logo" loading="lazy" className="img-fluid mb-4" />
  )

  return (
    <Wrapper className="py-lg-5" style={styles}>
      <ReferenceContainer>
        {extodkaz ? (
          <a
            href={extodkaz}
            target="_blank"
            rel="noreferrer"
            className="logo d-block"
          >
            {logoImage}
          </a>
        ) : (
          <div className="logo d-block">{logoImage}</div>
        )}

        <h1 style={changecolh}>{heading}</h1>
        {odstavec && (
          <p style={changecol} className="text-center">
            {odstavec}
          </p>
        )}
        {children}
      </ReferenceContainer>
    </Wrapper>
  )
}

export default ReferenceBanner
