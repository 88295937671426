import React, { FC } from "react"
import styled from "styled-components"
import { useField, FieldHookConfig, ErrorMessage } from "formik"

const FieldWrapper = styled.div`
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 15px;

  label,
  input,
  textarea,
  .error {
    transition: all 0.2s ease-in-out;
  }

  input,
  textarea {
    padding: 10px 20px;
    border: 1px solid ${(props) => props.theme.color.gray500};
    -webkit-appearance: none;
    border-radius: 4px;
    color: ${(props) => props.theme.color.gray900};
    cursor: text;
    font-weight: 500;

    &:focus {
      outline-color: ${(props) => props.theme.color.primary};
    }
  }

  label {
    margin-bottom: 3px;
    font-weight: 700;
    font-size: 15px;
  }

  .error {
    border: 1px solid ${(props) => props.theme.color.red};

    + label {
      color: ${(props) => props.theme.color.red};
    }

    &:focus {
      outline-color: ${(props) => props.theme.color.red};
    }
  }
`

const FieldError = styled.div`
  color: ${(props) => props.theme.color.red};
  margin-top: 2px;
  height: 21px;
  font-size: 14px;
`

const InputField: FC<FieldHookConfig<string>> = (props) => {
  const [field, meta] = useField(props)
  const FieldComponent = props.as ? props.as : `input`

  return (
    <FieldWrapper>
      <FieldError>
        <ErrorMessage name={field.name} />
      </FieldError>
      <FieldComponent
        {...field}
        {...props}
        placeholder=" "
        className={meta.error && meta.touched ? "error" : ""}
      />
      <label htmlFor={field.name}>{props.placeholder}</label>
    </FieldWrapper>
  )
}

export default InputField
