import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    width: 50%;
  }
`

const HalfWidthBlock = (props) => <Wrapper>{props.children}</Wrapper>
export default HalfWidthBlock
