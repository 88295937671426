import React from "react"
import styled, { css } from "styled-components"
import Link from "../link"

const wrapperStyle = css`
  display: block;
  padding: 10px;
  background: ${(props) => props.theme.color.white};
  box-shadow: 0 8px 36px rgba(0, 0, 0, 0.04);
  position: relative;
  width: 100%;

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    padding: 20px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    content: "";
    border: 1px solid ${(props) => props.theme.color.gray300};
    transition: all 0.2s ease-in-out;
  }

  img,
  svg {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  &[href]:hover:after,
  &[href]:focus:after {
    border: 3px solid ${(props) => props.theme.color.primary};
  }

  &[href]:hover + a,
  &[href]:focus + a {
    text-decoration: underline;
  }
`

const SpanWrapper = styled.span`
  ${wrapperStyle}
`
const LinkWrapper = styled(Link)`
  ${wrapperStyle}
`

const SquareBorderImageW = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &:before {
    padding-top: 100%;
    display: block;
    content: "";
  }
`

const SquareBorderImageItem = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`

const SquareBorder = (props) => {
  const Wrapper = props.href ? LinkWrapper : SpanWrapper
  return (
    <Wrapper className={props.className} to={props.href}>
      <SquareBorderImageW>
        <SquareBorderImageItem>{props.children}</SquareBorderImageItem>
      </SquareBorderImageW>
    </Wrapper>
  )
}

export default SquareBorder
