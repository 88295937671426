import React from "react"
import { Form, Formik } from "formik"
import styled from "styled-components"
import InputField from "./field-input"

interface Values {
  message: string
  contact: string
  recipient: string
}

interface FormProps {
  recipient: string
  buttonTitle?: string
  buttonSubtitle?: string
  messagePlaceholder?: string
  contactPlaceholder?: string
  emailPlaceholder?: string
  linkedinPlaceholder?: string
  cvPlaceholder?: string
  lang?: string
}

const FileInputWrapper = styled.div`
  input {
    background: ${(props) => props.theme.color.white};
  }
`

const ButtonSubmit = styled.button`
  display: inline-block;
  padding: 15px 30px;
  border: 1px solid transparent;
  border-radius: 4px;
  background: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.white};
  cursor: pointer;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus,
  &:visited,
  &:active {
    text-decoration: none;
  }

  &:hover,
  &:active {
    border-color: ${(props) => props.theme.color.primary};
    background-color: ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.primary};
    box-shadow: 0 0 20px 0 rgba(234, 129, 50, 0.3);
  }

  &:disabled,
  &.disabled {
    &:hover,
    &:focus,
    &:active {
      border-color: ${(props) => props.theme.color.primary};
      background-color: ${(props) => props.theme.color.primary};

      color: ${(props) => props.theme.color.white};
    }
  }
`
const Status = ({
  status,
}: {
  status: { msg?: string; sent: boolean; submited: boolean }
}) => {
  return (
    <>
      {status && status.msg && (
        <p
          className={`alert ${status.sent ? "alert-success" : "alert-danger"}`}
        >
          {status.msg}
        </p>
      )}
    </>
  )
}

const FormComponent = ({
  recipient,
  buttonTitle,
  buttonSubtitle,
  messagePlaceholder,
  contactPlaceholder,
  emailPlaceholder,
  linkedinPlaceholder,
  cvPlaceholder,
  lang,
}: FormProps): React.ReactElement => {
  const url = typeof window === "undefined" ? "" : window.location.href
  const formData = typeof FormData === "undefined" ? {} : new FormData()

  return (
    <Formik
      initialValues={{
        message: "",
        contact: "",
        recipient: recipient,
        file: null,
        page: url,
      }}
      onSubmit={async (values: Values, { setStatus, resetForm }) => {
        const isEnLanguage = lang === "en"
        /* append input field values to formData */
        for (const value in values) {
          formData.append(value, values[value])
        }

        await new Promise((r) => setTimeout(r, 500))

        fetch("https://posilator.igloonet.cz/", {
          method: "POST",
          mode: "cors",
          body: formData,
        })
          .then(function (response) {
            const msg = isEnLanguage
              ? "Successfully sent. I'll be in touch."
              : "Úspěšně odesláno. Ozvu se."
            setStatus(response.status)

            if (response.status === 422) {
              return Promise.reject(response.json())
            }

            // FIXME: nemělo by se tady kontrolovat, že je status 200 a jinak to vždy zahlásit chybu?
            resetForm()
            setStatus({
              sent: true,
              msg,
              submited: true,
            })
            return response.json()
          })
          .catch(() => {
            const msg = isEnLanguage
              ? "Well, that's a faux pas, we've broken it." +
                " Could you please contact us another way?"
              : "Tak tohle je faux pas, máme to polámané." +
                " Můžete nás prosím kontaktovat jinak?"
            setStatus({
              sent: false,
              msg,
              submited: true,
            })
          })
      }}
      validate={(values) => {
        const errors: { message?: string; contact?: string } = {}

        if (!values.message) {
          errors.message = "Zprávu je potřeba vyplnit"
        }
        if (!values.contact) {
          errors.contact = "Kontakt na vás je potřeba vyplnit"
        }
        return errors
      }}
    >
      {({ isSubmitting, setFieldValue, status }) => (
        <>
          <Status status={status} />
          <Form className={`${status && status.submited ? "d-none" : ""}`}>
            <InputField
              name="message"
              placeholder={messagePlaceholder}
              as="textarea"
              rows={5}
            />

            {emailPlaceholder ? (
              <>
                <InputField name="contact" placeholder={emailPlaceholder} />
                <InputField
                  name="linkedin"
                  type="text"
                  placeholder={linkedinPlaceholder}
                />

                <FileInputWrapper>
                  <InputField
                    name="file"
                    type="file"
                    placeholder={cvPlaceholder}
                    onChange={(event) => {
                      setFieldValue("file", event.currentTarget.files[0])
                    }}
                    value={undefined}
                    accept=".doc,.docx,.odt,.org,image/*,video/*,audio/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                </FileInputWrapper>
              </>
            ) : (
              <InputField name="contact" placeholder={contactPlaceholder} />
            )}

            <InputField type="hidden" name="recipient" />
            <InputField type="hidden" name="page" />

            <div className={"text-center"}>
              <ButtonSubmit type="submit" disabled={isSubmitting}>
                {buttonTitle}
              </ButtonSubmit>
              <div className={"mt-2"}>
                <b>{buttonSubtitle}</b>
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default FormComponent
