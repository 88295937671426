import React from "react"
import styled from "styled-components"

const DeviceWrapper = styled.div`
  height: 290.33px;
  width: 139.66px;
  position: relative;
`

const DeviceFrame = styled.div`
  background: #222222;
  border-radius: 22.66px;
  box-shadow: inset 0 0 0 0.66px #75757a, inset 0 0 0 2.33px #9b9ba0;
  height: 290.33px;
  padding: 34px 7.33px;
  width: 139.66px;
  overflow: hidden;
`

const DeviceContent = styled.img`
  border: 0.66px solid #222;
  border-radius: 1.33px;
  height: auto;
  width: 100%;
  overflow-y: hidden;
  background-repeat: no-repeat;
  background-size: 20%;
`

const DeviceStripe = styled.div`
  &:after,
  &:before {
    border: solid rgba(#ccc, 0.35);
    border-width: 0 2.33px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 9;
  }

  &:after {
    top: 22.66px;
  }

  &:before {
    bottom: 22.66px;
  }
`

const DeviceHeader = styled.div`
  border: 0.66px solid #c9cacb;
  border-radius: 50%;
  height: 19.33px;
  left: 50%;
  bottom: 8px;
  margin-left: -9.66px;
  position: absolute;
  width: 19.33px;
`

const DeviceSensors = styled.div`
  background: #666;
  border-radius: 1px;
  height: 2px;
  left: 50%;
  margin-left: -12.66px;
  position: absolute;
  top: 17.33px;
  width: 25.33px;

  &:after,
  &:before {
    background: #666;
    border-radius: 50%;
    content: "";
    position: absolute;
  }
  &:after {
    height: 3.33px;
    left: 50%;
    margin-left: -1.66px;
    top: -8.33px;
    width: 3.33px;
  }
  &:before {
    height: 4px;
    left: -14px;
    margin-top: -2px;
    top: 50%;
    width: 4px;
  }
`

const DeviceContentWrapper = styled.div`
  width: 100%;
  height: 220px;
  overflow: hidden;
  display: flex;
  align-items: start;
  justify-content: center;
`

export type IphoneProps = {
  backgroundSrc: string
  backgroundAlt: string
}

const Iphone: React.FC<IphoneProps> = ({ backgroundSrc, backgroundAlt }) => {
  return (
    <DeviceWrapper>
      <DeviceFrame>
        <DeviceContentWrapper>
          <DeviceContent src={backgroundSrc} alt={backgroundAlt} />
        </DeviceContentWrapper>
      </DeviceFrame>
      <DeviceStripe />
      <DeviceHeader />
      <DeviceSensors />
    </DeviceWrapper>
  )
}

export default Iphone
