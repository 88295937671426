import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const WrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  height: 80px;
  font-size: 15px;
  box-shadow: 0 0 24px 6px ${(props) => props.theme.color.gray300};
  background: #fff;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    height: 120px;
  }

  .img-wrapper {
    width: 16px;
    height: 16px;

    @media (min-width: ${(props) => props.theme.sizes_min.md}) {
      width: 40px;
      height: 40px;
    }
  }
`

const OrangeNum = styled.div`
  color: ${(props) => props.theme.color.primary};
  font-size: 15px;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    font-size: 26px;
  }
`

const TextWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.sizes_max.md}) {
    font-size: 12px;
  }
`
type TextualCubeDataProps = {
  description: string
  image?: never
  number: string
}

type GraphicalCubeDataProps = {
  description: string
  image: string
  number?: never
}

type CubeDataProps = TextualCubeDataProps | GraphicalCubeDataProps

type CubesProps = {
  data: Array<CubeDataProps>
}

const Cubes: React.FC<CubesProps> = (props) => {
  const { data } = props

  return (
    <Wrapper>
      {data.map((item, itemIndex) => (
        <WrapperInner key={"item-" + itemIndex}>
          {item.image && (
            <div className=" img-wrapper mx-auto ">
              <img src={item.image} alt="" className="img-fluid" />
            </div>
          )}

          {item.number && <OrangeNum>{item.number}</OrangeNum>}

          <TextWrapper>{item.description}</TextWrapper>
        </WrapperInner>
      ))}
    </Wrapper>
  )
}

export default Cubes
