import React from "react"
import styled, { css } from "styled-components"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import Linkedin from "../icons/social-icon/linkedin.svg"

const SliderUnderImage = styled.div`
  display: none;
  text-align: center;
`

const SliderWrapper = styled.div``

const SliderTitle = styled.div`
  color: ${(props) => props.theme.color.blue};
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 22px;
  position: relative;
  width: 80vw;
  left: calc(-40vw + 50%);

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    width: 40vw;
    left: calc(-20vw + 50%);
  }
`

const SliderSubTitle = styled.div`
  position: relative;
  margin-bottom: 15px;
  color: ${(props) => props.theme.color.primary};
  font-weight: 600;
  width: 80vw;
  left: calc(-40vw + 50%);

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    width: 40vw;
    left: calc(-20vw + 50%);
  }
`

const SliderDescription = styled.p`
  font-size: 16px;
  margin-bottom: 0;
  position: relative;
  width: 80vw;
  left: calc(-40vw + 50%);

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    width: 30vw;
    left: calc(-15vw + 50%);
  }
`
const SliderItem = styled.div`
  padding: 0 5px;
  max-width: 100%;
  text-align: center;

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    padding: 0 20px;
  }
`
const activeItem = css`
  img {
    filter: none;
  }

  ${SliderUnderImage} {
    visibility: visible;
    display: block;
    transition: all 0.2s ease-in-out;
  }
`
const CarouselCustom = styled(Carousel)`
  img {
    margin-bottom: 15px;
    max-width: 100%;
    filter: grayscale(100%);
    transition: all 0.5s ease-in-out;
  }

  .react-multi-carousel-item {
    align-items: flex-start;
  }

  .react-multiple-carousel__arrow {
    align-self: center;
    top: 100px;

    &,
    &:hover,
    &:focus {
      background-color: ${(props) => props.theme.color.primary};
      outline: 0;
    }
  }

  /* stylelint-disable selector-max-compound-selectors, selector-max-class */
  .react-multi-carousel-item--active + .react-multi-carousel-item--active {
    ${activeItem}
  }

  .react-multi-carousel-item--active
    + .react-multi-carousel-item--active
    + .react-multi-carousel-item--active {
    img {
      filter: grayscale(100%);
    }

    ${SliderUnderImage} {
      display: none;
    }
  }
  /* stylelint-enable selector-max-compound-selectors */

  @media (max-width: ${(props) => props.theme.sizes_max.md}) {
    .react-multi-carousel-item--active {
      ${activeItem}
    }
  }
`
const LinkWrapper = styled.div`
  position: relative;
  bottom: 1.5px;
  left: 10px;
  fill: ${(props) => props.theme.color.gray700};

  &:hover {
    fill: ${(props) => props.theme.color.black};
  }
`

const Slider = (props) => {
  return (
    <SliderWrapper
      style={{
        padding: "60px 0 90px",
        ...props.style,
      }}
    >
      {props.title && (
        <div className={"text-center"}>
          <h2 className={"d-inline-block"}>{props.title}</h2>
        </div>
      )}
      <CarouselCustom
        ssr={true}
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={5000}
        centerMode
        draggable={false}
        focusOnSelect
        infinite
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1300,
            },
            items: 3,
          },
          mobile: {
            breakpoint: {
              max: 767,
              min: 0,
            },
            items: 1,
          },
          tablet: {
            breakpoint: {
              max: 1300,
              min: 767,
            },
            items: 3,
          },
        }}
        showDots={false}
        slidesToSlide={1}
        swipeable
      >
        {props.data.map((item) => {
          return (
            <SliderItem key={item.id}>
              <img src={item.imageSrc} alt={item.name} />
              <SliderUnderImage>
                <SliderTitle>
                  {item.name}
                  {item.linkedin && (
                    <a href={item.linkedin} target="_blank" rel="noreferrer">
                      <LinkWrapper className="pb-1 test d-inline">
                        <Linkedin width="16" height="16" />
                      </LinkWrapper>
                    </a>
                  )}
                </SliderTitle>
                <SliderSubTitle>{item.position}</SliderSubTitle>
                <SliderDescription>{item.text}</SliderDescription>
              </SliderUnderImage>
            </SliderItem>
          )
        })}
      </CarouselCustom>
    </SliderWrapper>
  )
}

export default Slider
