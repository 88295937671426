import React from "react"
import styled from "styled-components"

const Wrapper = styled.ul`
  padding: 0;
  margin: 0 0 15px;

  > li {
    padding: 0 0 0 2rem;
    margin: 0;
    list-style-type: none;
    text-indent: -1.5rem;
    line-height: 2.5;
    font-weight: 500;

    &:before {
      display: inline-block;
      width: 1.5rem;
      padding-right: 0.5rem;
      color: ${(props) => props.theme.color.primary};
      content: "–";
      font-weight: 700;
      text-align: right;
    }
  }
`

const StyledList = (props) => (
  <Wrapper className={props.left ? "text-left" : ""}>{props.children}</Wrapper>
)

export default StyledList
