import React, { FC } from "react"
import styled from "styled-components"

const Title = styled.h2`
  display: inline-block;
`

interface TH2CProps {
  className?: string
  children: React.ReactNode
}

const TitleH2Center: FC<TH2CProps> = (props) => (
  <div className={"text-center"}>
    <Title className={props.className}>{props.children}</Title>
  </div>
)

export default TitleH2Center
