import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { Container } from "reactstrap"
import { Banner, TeamCollection } from ".."
import Extucnaci from "../images/extucnaci.png"

const Image = styled.img`
  display: block;

  margin: 50px auto;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    margin: 20px auto 70px;
  }
`
const ExEmployee: React.FC<Props> = ({
  employee,
  employees,
  profileImageTag,
}) => {
  const hisher = employee.sex === "male" ? "natřepal" : "natřepala"
  const hisher1 = employee.sex === "male" ? "zmizel" : "zmizela"
  const ended = employee.ended === "1. 1. 1970" ? "dávno" : employee.ended

  return (
    <>
      <Helmet>
        <title>ExTučňák {employee.nickname} | igloonet</title>
      </Helmet>
      <Banner>
        <h1>Mysleli jste, že tučnáci necestují do teplých krajin ? </h1>
        <p>
          {" "}
          Omyl! <strong>{employee.nickname}</strong> {hisher} peří a {ended}{" "}
          {hisher1} za velkou louží.
        </p>
      </Banner>
      <Container>
        <Image src={Extucnaci} alt="tucnaci" className="img-fluid" />

        {employee.teams.map((team, i) => {
          const hishers = employee.sex === "male" ? "jeho" : "jejího"
          return (
            <TeamCollection
              key={i}
              teamMembers={employees.filter((e) => e.teams.includes(team))}
              imageTag={profileImageTag}
              title={`Nevěšte hlavu, tučňáci z ${hishers} oddělení vám rádi pomůžou.`}
            />
          )
        })}
      </Container>
    </>
  )
}

export default ExEmployee
