import React from "react"
import LadyPenguin from "../images/universal-fm.jpg"
import MrPenguin from "../images/universal-ml.jpg"

type Node = {
  relativePath: string
  name: string
  publicURL: string
}

type GPIProps = ProfileImageProps & {
  allImages: Node[]
}

const ProfileImage: React.FC<GPIProps> = ({
  alt,
  filename,
  imageTag,
  allImages,
  ...other
}) => {
  const image = allImages.find((node: Node) => {
    return node.relativePath.includes(filename)
  })

  if (image === undefined) return null

  const Tag = imageTag === undefined ? `img` : imageTag

  // FIXME: pass sex somehow
  const sex = Math.random() < 0.5 ? "female" : "male"
  if (!image && sex === "female") {
    return <Tag alt={alt} src={LadyPenguin} {...other} />
  }

  if (!image && sex === "male") {
    return <Tag alt={alt} src={MrPenguin} {...other} />
  }

  //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
  // return <img alt={props.alt} fluid={image.node.childImageSharp.fluid} />
  return <Tag alt={alt} src={image.publicURL} {...other} />
}

export default ProfileImage
