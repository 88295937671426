import React from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"

type TimelineProps = {
  data: Array<{
    year: string
    header?: string
    linkLabel?: string
    odkaz?: string
    title?: string
    text?: string
  }>
}

const Wrapper = styled(Row)`
  padding-bottom: 3rem;
`

const RightCol = styled(Col)`
  padding: 0 0.9375rem;
  width: 100%;
  position: relative;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    content: " ";
  }

  @media (max-width: ${(props) => props.theme.sizes_max.md}) {
    &:before {
      left: 50%;
      background-color: ${(props) => props.theme.color.primary};
      height: 2rem;
      width: 2px;
    }
  }

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    border-left: 2px solid ${(props) => props.theme.color.primary};

    &:nth-of-type(1n) {
      left: -2px;
      border: 0;
      border-left: 2px solid ${(props) => props.theme.color.primary};
    }

    &:before,
    &:after {
      bottom: 0;
      display: block;
      border-radius: 50%;
      margin-top: auto;
      margin-bottom: auto;
    }

    &:before {
      left: -7.5px;
      width: 14px;
      height: 14px;
      background: ${(props) => props.theme.color.gray200};
    }

    &:after {
      left: -5px;
      width: 8px;
      height: 8px;
      background: ${(props) => props.theme.color.primary};
    }
  }
`
const RightWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    width: 50%;
  }
`

const LeftCol = styled(Col)`
  &:before,
  &:after {
    position: absolute;
    top: 0;
    content: " ";
  }

  @media (max-width: ${(props) => props.theme.sizes_max.md}) {
    &:before {
      left: 50%;
      background-color: ${(props) => props.theme.color.primary};
      height: 2rem;
      width: 2px;
    }
  }

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    border-right: 2px solid ${(props) => props.theme.color.primary};

    &:before,
    &:after {
      bottom: 0;
      display: block;
      border-radius: 50%;
      margin-top: auto;
      margin-bottom: auto;
    }

    &:before {
      right: -7.5px;
      width: 14px;
      height: 14px;
      background: ${(props) => props.theme.color.gray200};
    }

    &:after {
      right: -5px;
      width: 8px;
      height: 8px;
      background: ${(props) => props.theme.color.primary};
    }
  }
`

const H3 = styled.h3`
  @media (max-width: ${(props) => props.theme.sizes_max.md}) {
    &:before,
    &:after {
      top: -5.5rem;
      content: " ";
      position: absolute;
      bottom: 0;
      display: block;
      border-radius: 50%;
      margin-top: auto;
      margin-bottom: auto;
    }

    &:before {
      right: 47.5%;
      width: 14px;
      height: 14px;
      background: ${(props) => props.theme.color.gray200};
    }

    &:after {
      right: 48.5%;
      width: 8px;
      height: 8px;
      background: ${(props) => props.theme.color.primary};
    }
  }
`

const LeftWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    width: 50%;
    float: right;
  }
`

const Timeline: React.FC<TimelineProps> = ({ data }) => {
  return (
    <Wrapper>
      {data.map((item, index: number) => {
        return index % 2 === 0 ? (
          <RightCol
            key={index}
            md={{ span: 6, offset: 6 }}
            className="text-md-left"
          >
            <RightWrapper className="pt-5">
              <H3>{item.year}</H3>
              {item.header && (
                <strong className="d-block">{item.header}</strong>
              )}
              {item.linkLabel && (
                <a href={item.odkaz} title={item.title}>
                  {item.linkLabel}
                </a>
              )}
              {item.text && (
                <p className="text-center text-md-left">{item.text}</p>
              )}
            </RightWrapper>
          </RightCol>
        ) : (
          <LeftCol key={index} md={6} className="mr-auto text-md-right">
            <LeftWrapper className="pt-5">
              <H3 className="mb-0">{item.year}</H3>
              {item.header && (
                <strong className="d-block">{item.header}</strong>
              )}
              {item.linkLabel && (
                <a href={item.odkaz} title={item.title}>
                  {item.linkLabel}
                </a>
              )}
              {item.text && (
                <p className="text-center text-md-right">{item.text}</p>
              )}
            </LeftWrapper>
          </LeftCol>
        )
      })}
    </Wrapper>
  )
}

export default Timeline
