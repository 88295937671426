import React from "react"
import styled from "styled-components"

const Text = styled.span`
  color: ${(props) => props.theme.color.primary};
`

const TextPrimary = (props) => (
  <Text className={props.className}>{props.children}</Text>
)

export default TextPrimary
