import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "reactstrap"
import Link from "./link"

import IconBlog from "../icons/social-icon/blog.svg"
import IconFacebook from "../icons/social-icon/facebook.svg"
import IconInstagram from "../icons/social-icon/instagram.svg"
import IconLinkedin from "../icons/social-icon/linkedin.svg"

type TSocialIcons = {
  [key: string]: typeof IconFacebook
}

const SocialIcons: TSocialIcons = {
  blog: IconBlog,
  facebook: IconFacebook,
  instagram: IconInstagram,
  linkedin: IconLinkedin,
}

const FooterWrapper = styled.footer`
  width: 100%;
  padding: 30px 0 50px;
  background-color: ${(props) => props.theme.color.blue};

  svg {
    fill: ${(props) => props.theme.color.blue100};
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus {
      color: ${(props) => props.theme.color.primary};
      fill: ${(props) => props.theme.color.primary};
      text-decoration: none;
    }
  }

  @media (max-width: ${(props) => props.theme.sizes_max.sm}) {
    text-align: center;
  }
`

const FooterList: React.FC<Props> = ({ children }) => (
  <FooterListWrapper>
    <ul className="list-unstyled">{children}</ul>
  </FooterListWrapper>
)

const FooterListWrapper = styled.div.attrs({
  className: "col-lg-3 col-sm-4",
})`
  li {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    a {
      color: ${(props) => props.theme.color.blue100};
      font-size: 14px;

      &:hover,
      &:focus,
      &:active {
        color: ${(props) => props.theme.color.primary};
        text-decoration: none;
      }
    }
  }
`

type FLProps = Props & {
  /** Target link */
  to: string
}
const FooterLink: React.FC<FLProps> = ({ to, children }) => (
  <li>
    <Link to={to} activeClassName="active">
      {children}
    </Link>
  </li>
)

const SocialLinks = styled(Row)`
  padding-top: 20px;

  > a {
    border-right: none;
    padding: 1rem;
    width: 25%;
    text-decoration: none;

    @media (max-width: ${(props) => props.theme.sizes_max.sm}) {
      > div {
        display: flex;
        justify-content: center;
      }
    }

    @media (min-width: ${(props) => props.theme.sizes_min.xl}) {
      &:last-child {
        border: none;
      }
    }
  }
`

const SocialIconLink = styled(Link)`
  color: ${(props) => props.theme.color.blue100};
  font-size: 14px;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    &,
    svg {
      color: ${(props) => props.theme.color.primary};
      fill: ${(props) => props.theme.color.primary};
      text-decoration: none;
    }
  }
`

const SocialIconName = styled.div`
  font-size: 18px;

  @media (min-width: ${(props) => props.theme.sizes_min.xl}) {
    font-size: 22px;
  }
`

type SocialIconBoxProps = {
  /** Link to Social network */
  to: string
  /** Name of network */
  name: string
  /** Text under icon and name */
  text: string
}

const SocialIconBox: React.FC<SocialIconBoxProps> = ({ to, name, text }) => {
  const lname = name.toLowerCase()
  if (!(lname in SocialIcons)) return null

  const Icon = SocialIcons[lname]

  return (
    <SocialIconLink to={to}>
      <div className="mb-2 d-flex align-items-center">
        <Icon width={32} height={32} />
        <SocialIconName className="ml-2 d-none d-md-block">
          {name}
        </SocialIconName>
      </div>
      <div className={"d-none d-lg-block"}>{text}</div>
    </SocialIconLink>
  )
}

type Props = {
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode
}

type FLSProps = Props & {
  /** Passed svg ReactNode */
  Logo: typeof IconBlog
}

const FooterLinks: React.FC<FLSProps> = ({ children, Logo }) => {
  return (
    <Row>
      <Col lg={3} className="mb-4 mb-lg-0">
        <Link to="/">
          <Logo width={150} />
        </Link>
      </Col>
      {}
      {children}
    </Row>
  )
}

const Footer: React.FC<Props> = ({ children }) => {
  return (
    <FooterWrapper>
      <Container>{children}</Container>
    </FooterWrapper>
  )
}

export { FooterList, FooterLinks, FooterLink, SocialLinks, SocialIconBox }
export default Footer
