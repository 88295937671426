import React from "react"
import styled from "styled-components"
import LogoJobs from "../../images/logo-jobs.png"

const JobDetailHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0.0625rem solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 0.0625rem);
  border-top-right-radius: calc(0.3rem - 0.0625rem);
`
const JobDetailClose = styled.a`
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
  color: ${(props) => props.theme.color.primary};
  width: 40px;
`

const JobDetailTitle = styled.h5`
  margin-bottom: 0;
  line-height: 1.5;
`

const JobHeader: React.FC = () => (
  <JobDetailHeader>
    <JobDetailTitle>
      <img src={LogoJobs} alt="logo jobs" width="182" height="39" />
    </JobDetailTitle>
    <JobDetailClose href="/jobs">×</JobDetailClose>
  </JobDetailHeader>
)

export default JobHeader
