import React from "react"

import EdgeBorder from "../components/edge-border"

const EdgeBorderFluid = (props) => (
  <EdgeBorder>
    <div className="EdgeBorderContent">{props.children}</div>
  </EdgeBorder>
)

export default EdgeBorderFluid
