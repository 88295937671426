/* stylelint-disable value-keyword-case */
import React from "react"
import styled, { css } from "styled-components"

import Arrow from "@igloonet-web/shared-ui/icons/arrow-orange-r.svg"
import Link from "@igloonet-web/shared-ui/components/link"

export enum ButtonStyle {
  Banner = "banner",
  Outline = "outline",
  OutlinePrimary = "outline-primary",
  Primary = "primary",
  White = "white",
  Undefined = "undefined",
}

export enum ButtonSize {
  Default = "default",
  Small = "small",
}

export enum ButtonWidth {
  Default = "default",
  FullWidthOnSmall = "full-width-on-small",
}

type SharedProps = {
  /** Possibility to show button as block elements */
  block?: boolean
  /** Custom additional class name */
  className?: string
  /** Disabled state of component */
  disabled?: boolean
  /** Additional onClick callback */
  onClick?: (e: React.MouseEvent) => void
  /** Size of button */
  size?: ButtonSize
  /** The URL you want to link to */
  to?: string
  /** Used to pass state data to the linked page */
  state?: Record<string, unknown>
  /** Main style of button */
  style: ButtonStyle
  /** Specific width setup for button */
  width?: ButtonWidth
}

type LinkProps = SharedProps & {
  /** Additional onClick callback */
  onClick?: (e: React.MouseEvent) => void
  renderAs?: never
  type?: never
}

type ButtonProps = SharedProps & {
  /** Required onClick callback */
  onClick: (e: React.MouseEvent) => void
  /** Possibility to render button as <button> tag (not link) */
  renderAs: "button"
  /** Type of <button> tag */
  type?: React.AllHTMLAttributes<HTMLButtonElement>["type"]
}

const Button: React.FC<LinkProps | ButtonProps> = (props) => (
  <StyledButton {...props} />
)

const StyledButton = styled((props) => {
  const { block, renderAs, style, size, ...otherProps } = props

  const Component = renderAs || Link
  return <Component {...otherProps} />
})`
  display: inline-block;
  padding: 15px 30px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: ${(props) => props.theme.color.white};
  cursor: pointer;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;

  svg {
    transition: all 0.2s ease-in-out;
  }

  .icon {
    margin-left: 10px;
  }

  &:hover,
  &:focus,
  &:visited,
  &:active {
    text-decoration: none;
  }

  &.disabled,
  &[disabled],
  &:disabled {
    cursor: default;
    opacity: 0.65;
    text-decoration: none;
    user-select: none;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      cursor: default;
      opacity: 0.65;
      text-decoration: none;
      user-select: none;
    }
  }

  /* stylelint-disable order/properties-order */
  ${(props) =>
    props.style === ButtonStyle.Primary &&
    css`
      background: ${(props) => props.theme.color.primary};
      color: ${(props) => props.theme.color.white};

      &:hover,
      &:active {
        border-color: ${(props) => props.theme.color.primary};
        background-color: ${(props) => props.theme.color.white};
        color: ${(props) => props.theme.color.primary};
        box-shadow: 0 0 20px 0 rgba(234, 129, 50, 0.3);
      }

      &:disabled,
      &[disabled],
      &.disabled {
        &:hover,
        &:focus,
        &:active {
          border-color: ${(props) => props.theme.color.primary};
          background-color: ${(props) => props.theme.color.primary};

          color: ${(props) => props.theme.color.white};
        }
      }
    `}

  ${(props) =>
    props.style === ButtonStyle.Outline &&
    css`
      border: 1px solid ${(props) => props.theme.color.gray700};
      background: transparent;
      color: ${(props) => props.theme.color.gray900};

      &:hover,
      &:active {
        border-color: ${(props) => props.theme.color.primary};
        background-color: ${(props) => props.theme.color.primary};
        box-shadow: 0 0 20px 0 rgba(234, 129, 50, 0.3);
        color: ${(props) => props.theme.color.white};

        svg {
          fill: ${(props) => props.theme.color.white};
        }
      }

      &:disabled,
      &[disabled],
      &.disabled {
        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${(props) => props.theme.color.gray700};
          background: transparent;
          box-shadow: none;
          color: ${(props) => props.theme.color.gray900};
        }
      }
    `}

  ${(props) =>
    props.style === ButtonStyle.OutlinePrimary &&
    css`
      border-color: ${(props) => props.theme.color.primary};
      background: transparent;
      color: ${(props) => props.theme.color.primary};

      &:hover,
      &:active {
        background-color: ${(props) => props.theme.color.primary};
        box-shadow: 0 0 20px 0 rgba(234, 129, 50, 0.3);
        color: ${(props) => props.theme.color.white};
      }

      &:disabled,
      &[disabled],
      &.disabled {
        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${(props) => props.theme.color.primary};
          background: transparent;
          box-shadow: none;
          color: ${(props) => props.theme.color.primary};
        }
      }
    `}

  ${(props) =>
    props.style === ButtonStyle.White &&
    css`
      background: ${(props) => props.theme.color.white};
      color: ${(props) => props.theme.color.blue};

      &:hover,
      &:active {
        background-color: ${(props) => props.theme.color.primary};
        box-shadow: 0 0 20px 0 rgba(234, 129, 50, 0.3);
        color: ${(props) => props.theme.color.white};
      }

      &:disabled,
      &[disabled],
      &.disabled {
        &:hover,
        &:focus,
        &:active {
          background: ${(props) => props.theme.color.white};
          box-shadow: none;
          color: ${(props) => props.theme.color.blue};
        }
      }
    `}

  ${(props) =>
    props.style === ButtonStyle.Banner &&
    css`
      background: ${(props) => props.theme.color.white};
      color: ${(props) => props.theme.color.blue};
      padding-left: 40px;
      padding-right: 40px;
      overflow: hidden;

      svg {
        margin-top: -1px;
      }

      &:before {
        //TODO FIX ME melo by byt v icons
        background: url(${Arrow}) center no-repeat;
        content: "";
        position: absolute;
        line-height: 3.5;
        color: #fff;
        transition: all 0.3s;
        width: 10px;
        height: 14px;
        right: 20px;
        top: -50%;
        transform: translateY(-50%) rotate(90deg);
      }

      &:hover,
      &:active {
        background-color: ${(props) => props.theme.color.white};
        color: ${(props) => props.theme.color.blue};
        &:before {
          top: calc(50%);
        }
      }

      &:disabled,
      &[disabled],
      &.disabled {
        &:hover,
        &:focus,
        &:active {
          background: ${(props) => props.theme.color.white};
          box-shadow: none;
          color: ${(props) => props.theme.color.gray900};
        }

        svg {
          fill: ${(props) => props.theme.color.gray900};
        }
      }
    `}

  ${(props) =>
    props.block &&
    css`
      display: block;
      width: 100%;
    `}

  ${(props) =>
    props.size === ButtonSize.Small &&
    css`
      padding: 4px 8px;
    `}

  ${(props) =>
    props.width === ButtonWidth.FullWidthOnSmall &&
    css`
      width: 100%;

      @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
        width: auto;
      }
    `}
`

export default Button
