import React from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  defaults,
} from "chart.js"

import { Bar } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { useInView } from "react-intersection-observer"

ChartJS.register(CategoryScale, LinearScale, BarElement)

defaults.font.family = "Montserrat,sans-serif"

const BarChart = (props) => {
  const { ref, inView } = useInView({
    threshold: 0,
  })
  return (
    <div ref={ref}>
      {inView && (
        <Bar
          data={props.data}
          plugins={[ChartDataLabels]}
          height={50}
          width={100}
          loading={"lazy"}
          options={{
            animation: {
              delay: 530,
              duration: 4800,
            },
          }}
        />
      )}
    </div>
  )
}

export default BarChart
