import React from "react"
import styled from "styled-components"
import { Container } from "reactstrap"
import BannerBg from "../images/tucnak/tucnak.svg"

const BannerW = styled.div`
  padding: 40px 0;
  background-color: ${(props) => props.theme.color.primary};
  background-repeat: no-repeat;
  text-align: center;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    padding: 100px 0 100px 50%;
    background-size: 350px;
    background-position: calc(50% - 200px) 30px;
    background-image: url(${BannerBg});
    text-align: left;
  }

  h1 {
    margin-bottom: 5px;
    color: ${(props) => props.theme.color.white};
    font-size: 48px;

    @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
      font-size: 56px;
    }
  }

  p {
    color: ${(props) => props.theme.color.white};
    font-size: 16px;
    margin-bottom: 0;

    @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
      font-size: 24px;
    }
  }
`

const Banner = (props) => (
  <BannerW>
    <Container>{props.children}</Container>
  </BannerW>
)

export default Banner
