import React from "react"
import styled from "styled-components"

import Quote from "../quote"

type MedailonProps = {
  wide?: boolean
  image: string
  alt: string
  text: string
  name: string
  position: string
}

const Wrapper = styled.div`
  padding: 3.5rem 0 1rem;
  margin: 4rem auto;
  box-shadow: 0 0 24px 6px ${(props) => props.theme.color.gray300};
  position: relative;
  width: 100%;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    margin: 1rem auto;
    padding: 4rem 1rem 1rem 1rem;

    &.responsive-width {
      width: 50%;
    }
  }

  .imgwrapper {
    width: 6rem;
    height: 6rem;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      border-radius: 50%;
    }
  }

  h6 {
    color: ${(props) => props.theme.color.primary};
  }
`

const Medailon: React.FC<MedailonProps> = ({
  wide,
  image,
  alt,
  text,
  name,
  position,
  children,
}) => {
  return (
    <Wrapper className={wide ? "responsive-width" : "w-100"}>
      <div className="imgwrapper">
        <img className="img-fluid" loading="lazy" src={image} alt={alt} />
      </div>
      <Quote>
        {children ? children : null}
        {text ? <p>{text}</p> : null}
      </Quote>
      <h6>{name}</h6>
      <h6 className="text-muted">{position}</h6>
    </Wrapper>
  )
}

export default Medailon
