import React from "react"
import { Container } from "reactstrap"
import styled from "styled-components"

const ContentWrapper = styled(Container)`
  h2 {
    margin-top: 1.8em;
    margin-bottom: 1em;
  }

  h2:first-child {
    margin-top: 0;
  }

  li {
    line-height: 2em;
  }

  p {
    font-size: 16px;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  ol {
    list-style: none;
    counter-reset: my-awesome-counter 0;
    > li {
      counter-increment: my-awesome-counter;
      padding: 0 0 0 0.5em;
    }

    > li::marker {
      color: ${(props) => props.theme.color.primary};
      content: counter(my-awesome-counter) ".";
      font-weight: 700;
    }
  }

  ul {
    margin: 0 0 15px;

    > li {
      margin: 0;
      list-style-type: none;
      text-indent: -1.5rem;

      &:before {
        display: inline-block;
        width: 1.5rem;
        padding-right: 0.5rem;
        color: ${(props) => props.theme.color.primary};
        content: "–";
        font-weight: 700;
        text-align: right;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    dl {
      display: grid;
      column-gap: em;
      grid-template-columns: max-content auto;

      > div {
        display: contents;
      }

      dt {
        grid-column-start: 1;

        &:before {
          content: " –";
          color: ${(props) => props.theme.color.primary};
          padding-right: 0.5em;
        }
      }

      dd {
        grid-column-start: 2;
        margin-bottom: 0;

        &:before {
          content: "::";
          padding: 0 0.5em;
          font-family: SFMono-Regular, Menlo, Monaco, Consolas,
            "Liberation Mono", "Courier New", monospace;
          color: ${(props) => props.theme.color.gray700};
        }
      }
    }
  }

  pre {
    background-color: ${(props) => props.theme.color.gray200};
  }

  code {
    background-color: ${(props) => props.theme.color.gray200};
    padding: 0.2em 0.5em;
  }
`

const Content: React.FC<ChildrenProp> = ({ children }) => (
  <ContentWrapper className="py-5">{children}</ContentWrapper>
)

export default Content
