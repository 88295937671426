import React from "react"
import styled from "styled-components"
import Iphone, { IphoneProps } from "./iphone"
import Laptop, { LaptopProps } from "./laptop"

const Wrapper = styled.div`
  position: relative;
  justify-content: center;
  margin-top: -8rem;
  padding-bottom: 2rem;
  overflow: hidden;
`
const DeviceWrapper = styled.div`
  position: relative;
  justify-content: center;
  margin-top: -8rem;
  top: 3rem;
  left: 0;
  padding-bottom: 2rem;
  z-index: 100;

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    left: 20vw;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.xl}) {
    left: 13vw;
  }
`

type MediaProps = {
  iPhone: IphoneProps
  laptop: LaptopProps
}

const Media: React.FC<MediaProps> = (props) => {
  const { iPhone, laptop } = props
  return (
    <div>
      <Wrapper>
        <div className="d-none d-lg-flex">
          <Iphone
            backgroundSrc={iPhone.backgroundSrc}
            backgroundAlt={iPhone.backgroundAlt}
          />
          <Laptop
            backgroundSrc={laptop.backgroundSrc}
            backgroundAlt={laptop.backgroundAlt}
          />
        </div>
      </Wrapper>

      <DeviceWrapper className="d-flex d-lg-none">
        <Iphone
          backgroundSrc={iPhone.backgroundSrc}
          backgroundAlt={iPhone.backgroundAlt}
        />
      </DeviceWrapper>
    </div>
  )
}

export default Media
