import React from "react"
import { Helmet } from "react-helmet"
import { ThemeProvider } from "styled-components"
import theme from "../../themes/igloonet"
import GlobalStyle from "../global_style"

import favicon from "../../images/favicon.ico"
import favicon16x16 from "../../images/favicon-16x16.png"
import favicon32x32 from "../../images/favicon-32x32.png"

type Props = {
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Helmet>
      <link rel="shortcut icon" type="image/x-icon" href={favicon} />
      <link rel="icon" type="image/x-icon" href={favicon} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
    </Helmet>
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

export default Layout
