import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "reactstrap"

import Link from "./link"
import SquareBorder from "./square-border/square-border"
import SquareBorderPrimary from "./square-border/square-border-primary"
import StyledList from "./styled-list"
import TextPrimary from "./text-primary"
import TitleH2 from "./title/title-h2"

import ImageExponea from "../images/services/exponea.jpg"
import ImageFacebookAds from "../images/services/fbads.jpg"
import ImageGA from "../images/services/google-analytics.jpg"
import ImageGCP from "../images/services/gcp.jpg"
import ImageGoogleAds from "../images/services/googleads.jpg"
import ImageMailKit from "../images/services/mailkit.jpg"
import ImageSklik from "../images/services/sklik.jpg"
import ImageSupermetric from "../images/services/supermetric.jpg"
import LogoIconWhite from "../icons/logo-icon-white.svg"

const IconNetworkSectionW = styled.div`
  padding: 30px 0 40px;
  background: ${(props) => props.theme.color.white};
  border-top: 1px solid ${(props) => props.theme.color.gray300};

  @media (min-width: ${(props) => props.theme.sizes_min.sm}) {
    padding: 120px 0 50px;
    background: ${(props) => props.theme.color.gray200};
    border: none;
  }
`
const NetworkGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 8px 8px;

  grid-template-areas: "NetworkGridColumnFirst NetworkGridColumnSecond NetworkGridColumnSecond" "NetworkGridColumnThird NetworkGridColumnThird NetworkGridColumnThird" "NetworkGridColumnForth NetworkGridColumnForth NetworkGridColumnFifth";

  .NetworkGridColumnSecond,
  .NetworkGridColumnThird,
  .NetworkGridColumnForth {
    gap: 8px 8px;
    grid-template-rows: 1fr;
  }

  .NetworkGridColumnSecond,
  .NetworkGridColumnForth {
    grid-template-columns: 1fr 1fr;
  }

  .NetworkGridColumnSecond {
    grid-area: NetworkGridColumnSecond;
  }

  .NetworkGridColumnThird {
    grid-template-columns: 1fr 1fr 1fr;
    grid-area: NetworkGridColumnThird;
  }

  .NetworkGridColumnForth {
    grid-area: NetworkGridColumnForth;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    gap: 40px 40px;
    grid-template-areas: "NetworkGridColumnFirst NetworkGridColumnSecond NetworkGridColumnThird NetworkGridColumnForth NetworkGridColumnFifth";

    .NetworkGridColumnSecond,
    .NetworkGridColumnForth {
      gap: 40px 40px;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
    }

    .NetworkGridColumnThird {
      gap: 40px 40px;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
    }
  }
`

const NetworkGridColumn = styled.div`
  display: grid;
  width: 100%;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    row-gap: 40px;
  }
`

const IconNetworkSection = () => (
  <IconNetworkSectionW>
    <Container>
      <Row>
        <Col lg={"auto"}>
          <TitleH2>
            Na co se <TextPrimary>zaměřujeme?</TextPrimary>
          </TitleH2>
          <StyledList>
            <li>PPC</li>
            <li>Automatizace</li>
            <li>Sociální sítě</li>
            <li>Mailing</li>
            <li>Datová analytika</li>
            <li>
              <Link to="/hr-marketing">HR marketing</Link>
            </li>
          </StyledList>
        </Col>
        <Col>
          <NetworkGridWrapper>
            <NetworkGridColumn className={"NetworkGridColumnFirst"}>
              <SquareBorder>
                <img src={ImageExponea} loading={"lazy"} alt={""} />
              </SquareBorder>
            </NetworkGridColumn>
            <NetworkGridColumn className={"NetworkGridColumnSecond"}>
              <SquareBorder>
                <img src={ImageFacebookAds} loading={"lazy"} alt={""} />
              </SquareBorder>
              <SquareBorder>
                <img src={ImageGCP} loading={"lazy"} alt={""} />
              </SquareBorder>
            </NetworkGridColumn>
            <NetworkGridColumn className={"NetworkGridColumnThird"}>
              <SquareBorder>
                <img src={ImageGoogleAds} loading={"lazy"} alt={""} />
              </SquareBorder>
              <SquareBorderPrimary>
                <LogoIconWhite loading={"lazy"} />
              </SquareBorderPrimary>
              <SquareBorder>
                <img src={ImageMailKit} loading={"lazy"} alt={""} />
              </SquareBorder>
            </NetworkGridColumn>
            <NetworkGridColumn className={"NetworkGridColumnForth"}>
              <SquareBorder>
                <img src={ImageSklik} loading={"lazy"} alt={""} />
              </SquareBorder>
              <SquareBorder>
                <img src={ImageGA} loading={"lazy"} alt={""} />
              </SquareBorder>
            </NetworkGridColumn>
            <NetworkGridColumn className={"NetworkGridColumnFifth"}>
              <SquareBorder>
                <img src={ImageSupermetric} loading={"lazy"} alt={""} />
              </SquareBorder>
            </NetworkGridColumn>
          </NetworkGridWrapper>
        </Col>
      </Row>
    </Container>
  </IconNetworkSectionW>
)

export default IconNetworkSection
