import React from "react"
import { Helmet } from "react-helmet"
import { Container } from "reactstrap"
import { Banner, TeamProfile, TeamCollection } from "@igloonet-web/shared-ui"

const ActiveEmployee: React.FC<Props> = ({
  employee,
  employees,
  teamName,
  profileImageTag,
  alt,
}) => {
  return (
    <>
      <Helmet>
        <title>Tučňák {employee.nickname} | igloonet</title>
      </Helmet>
      <Banner>
        <h1>Tučňák {employee.nickname} </h1>
      </Banner>

      <Container>
        <TeamProfile
          profileImageTag={profileImageTag}
          employee={employee}
          alt={alt}
        />

        {employee.teams.map((team, i) => {
          return (
            <TeamCollection
              key={i}
              teamMembers={employees.filter(
                (e) => e.teams.includes(team) && e.login !== employee.login
              )}
              imageTag={profileImageTag}
              alt={alt}
              title={`Kolegové z týmu ${teamName(team)}`}
            />
          )
        })}
      </Container>
    </>
  )
}

export default ActiveEmployee
