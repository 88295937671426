import React from "react"
import styled from "styled-components"

const Item = styled.ul`
  padding-left: 35px;
  position: relative;
  list-style-type: none;
  margin: 0 0 0 30px;

  @media (min-width: ${(props) => props.theme.sizes_min.sm}) {
    margin: 0;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 7px;
    content: "";
    width: 2px;
    height: 100%;
    display: block;
    background-clip: content-box;
    background-color: #e87722;
    padding: 10px 0;
  }
`

const VerticalLineListWrapper = (props) => <Item>{props.children}</Item>

export default VerticalLineListWrapper
