import React from "react"
import { Row, Col } from "reactstrap"
import Button, { ButtonStyle, ButtonWidth } from "../buttons/button"
import styled from "styled-components"
import classnames from "classnames"

type BlockImageTextHalfProps = {
  right?: boolean
  hideImageOnSmall?: boolean
  image: string
  imageSrcSet?: string
  alt: string
  width?: string
  height?: string
  buttonLink?: string
  buttonText?: string
  noLazy?: boolean
  children: React.ReactNode
}

const BlockImageTextHalfWrapper = styled(Row)`
  p {
    text-align: center;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    p {
      text-align: left;
    }
  }
`

type LoadingType = undefined | "eager" | "lazy"

const BlockImageTextHalf: React.FC<BlockImageTextHalfProps> = ({
  right,
  hideImageOnSmall,
  image,
  imageSrcSet,
  alt,
  width,
  height,
  children,
  buttonLink,
  buttonText,
  noLazy,
}) => {
  const imageProps = {
    className: classnames("m-auto", "img-fluid", {
      "d-none": hideImageOnSmall,
      "d-lg-block": hideImageOnSmall,
    }),
    src: image,
    srcSet: imageSrcSet,
    alt: alt,
    loading: (noLazy ? undefined : "lazy") as LoadingType,
    width,
    height,
  }

  return (
    <BlockImageTextHalfWrapper className={"align-items-center py-5"}>
      <Col
        lg={6}
        className={classnames("px-0", "px-sm-3", "text-center", {
          "order-lg-2": right,
        })}
      >
        <div>
          <img {...imageProps} />
        </div>
      </Col>

      <Col>
        {children}
        {buttonLink && (
          <Button
            to={buttonLink}
            style={ButtonStyle.Primary}
            width={ButtonWidth.FullWidthOnSmall}
          >
            {buttonText}
          </Button>
        )}
      </Col>
    </BlockImageTextHalfWrapper>
  )
}

export default BlockImageTextHalf
