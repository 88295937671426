import React from "react"
import styled from "styled-components"
import TextTruncate from "react-text-truncate"
import { PaddingWrapper } from "@igloonet-web/shared-ui"

type GalleryDataProps = {
  id?: string
  imageSrc: string
  alt?: string
  text?: string
}

type GalleryProps = {
  data: Array<GalleryDataProps>
}

const ReferenceContainer = styled.div`
  background: ${(props) => props.theme.color.gray200};
`

const Img = styled.img`
  object-fit: cover;
  width: 270px;
  height: 270px;
  flex: 0 0 20%;
`

const Text = styled(TextTruncate)`
  display: block;
  color: ${(props) => props.theme.color.white};
  position: absolute;
  font-weight: 700;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  height: 540px;
  overflow: hidden;
`

const WrapperInner = styled.div`
  width: 270px;
  height: 270px;

  &:hover .overlay {
    opacity: 0.9;
  }

  .overlay {
    position: relative;
    top: -270px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 270px;
    height: 270px;
    opacity: 0;
    transition: 0.5s ease;
    background-color: ${(props) => props.theme.color.primary};
  }
`

const Gallery: React.FC<GalleryProps> = (props) => {
  const { data } = props

  return (
    <PaddingWrapper>
      <ReferenceContainer>
        <Wrapper>
          {data.map((item, i) => (
            <WrapperInner key={i}>
              <Img src={item.imageSrc} alt={item.alt} />
              <div className="overlay">
                {item.text && (
                  <Text
                    line={2}
                    element="div"
                    truncateText="…"
                    text={item.text}
                  />
                )}
              </div>
            </WrapperInner>
          ))}
        </Wrapper>
      </ReferenceContainer>
    </PaddingWrapper>
  )
}

export default Gallery
