import React from "react"
import styled from "styled-components"

import { Col, Container, Row } from "reactstrap"

import Button, { ButtonStyle } from "./buttons/button"
import SquareBorder from "./square-border/square-border"
import TitleH2Center from "./title/title-h2-center"

const ReferencesWrapper = styled.div`
  padding: 50px 0 80px;
  background: ${(props) => props.theme.color.gray200};
`

const SquareBorderCustom = styled(SquareBorder)`
  @media (max-width: ${(props) => props.theme.sizes_max.lg}) {
    margin-bottom: 30px;
  }
`

const ReferencesNext = (props) => (
  <ReferencesWrapper>
    <Container>
      <TitleH2Center>Další reference</TitleH2Center>
      <Row className="justify-content-center text-center">
        {props.data.map((reference, index) => {
          return (
            <Col key={index} xs={6} md={4} lg>
              <SquareBorderCustom href={reference.href}>
                <img
                  className="img-fluid"
                  src={reference.imageSrc}
                  alt={reference.imageAlt}
                  loading={"lazy"}
                />
              </SquareBorderCustom>
            </Col>
          )
        })}
      </Row>
      <div className={"text-center mt-lg-4"}>
        <Button to={props.buttonLink} style={ButtonStyle.Primary}>
          Zobrazit více
        </Button>
      </div>
    </Container>
  </ReferencesWrapper>
)

export default ReferencesNext
