import React from "react"
import styled from "styled-components"

const Title = styled.h3`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  svg,
  img {
    flex: none;
    margin-right: 18px;
  }
`

const TitleH3 = ({ className, children }) => (
  <Title className={className}>{children}</Title>
)

export default TitleH3
