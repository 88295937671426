import React from "react"
import styled from "styled-components"

import { Container } from "reactstrap"

const PerexInner = styled.div`
  padding: 100px 0 40px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.sizes_max.md}) {
    padding: 50px 0 0 0;

    h1 {
      font-size: 42px;
    }
  }

  small {
    display: table;
    margin: 5px 0 0 auto;
    border-top: 3px solid ${(props) => props.theme.color.primary};
    color: ${(props) => props.theme.color.primary};
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.3px;
    line-height: 24px;
  }
`

const Perex = ({ children }) => (
  <Container>
    <PerexInner>{children}</PerexInner>
  </Container>
)

export default Perex
