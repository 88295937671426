import React from "react"
import styled from "styled-components"
import { Container } from "reactstrap"
import BannerBg from "../../images/banner-bg.jpg"

const BannerW = styled.div`
  padding: 80px 0 60px;
  background: url(${BannerBg}) center no-repeat;
  background-color: ${(props) => props.theme.color.primary};
  background-size: cover;
  text-align: center;
  h1 {
    margin-bottom: 10px;
    color: ${(props) => props.theme.color.white};

    a {
      color: ${(props) => props.theme.color.white};
      &:hover {
        color: ${(props) => props.theme.color.white};
      }
    }
  }

  p {
    color: ${(props) => props.theme.color.white};
    font-size: 16px;
    margin-bottom: 2rem;
    @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
      font-size: 22px;
    }
    /* FIXME: hack pro lepší čitelnost textů
     * budeme chtít oddělat jak přejdeme na nové hlavičky */
    text-shadow: 1px 1px 0 ${(props) => props.theme.color.primary},
      1px -1px 0 ${(props) => props.theme.color.primary},
      -1px 1px 0 ${(props) => props.theme.color.primary},
      -1px -1px 0 ${(props) => props.theme.color.primary},
      2px 2px 0 ${(props) => props.theme.color.primary},
      2px -2px 0 ${(props) => props.theme.color.primary},
      -2px 2px 0 ${(props) => props.theme.color.primary},
      -2px -2px 0 ${(props) => props.theme.color.primary},
      3px 3px 0 ${(props) => props.theme.color.primary},
      3px -3px 0 ${(props) => props.theme.color.primary},
      -3px 3px 0 ${(props) => props.theme.color.primary},
      -3px -3px 0 ${(props) => props.theme.color.primary},
      4px 4px 0 ${(props) => props.theme.color.primary},
      4px -4px 0 ${(props) => props.theme.color.primary},
      -4px 4px 0 ${(props) => props.theme.color.primary},
      -4px -4px 0 ${(props) => props.theme.color.primary};
  }
`

const Banner = (props) => (
  <BannerW>
    <Container>{props.children}</Container>
  </BannerW>
)

export default Banner
