import React from "react"
import styled from "styled-components"
import { Row, Col } from "reactstrap"

const Wrapper = styled(Row)`
  padding: 1rem;
`

type ImageProps = {
  src: string
  alt?: string
}

export type ReferenceBannerContentProps = {
  image?: ImageProps
  mainText: string
  secondaryText?: string
  textStyle?: React.CSSProperties
}

const ReferenceBannerContent: React.FC<ReferenceBannerContentProps> = ({
  image,
  mainText,
  secondaryText,
  textStyle,
}) => {
  return (
    <Wrapper>
      <Col md={image ? 6 : 12}>
        <p style={textStyle} className="mb-4 w-100 pt-0 text-left">
          {mainText}
        </p>
        {secondaryText && (
          <p style={textStyle} className="w-100 text-left">
            {secondaryText}
          </p>
        )}
      </Col>

      {image && (
        <Col md={6}>
          <img
            className="img-fluid"
            src={image.src}
            alt={image.alt}
            loading="lazy"
          />
        </Col>
      )}
    </Wrapper>
  )
}

export default ReferenceBannerContent
