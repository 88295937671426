import React from "react"
import { Link } from "@igloonet-web/shared-ui"
import styled from "styled-components"
import { Col, Row } from "reactstrap"

import PaddingWrapper from "./padding-wrapper"
import TitleH2 from "./title/title-h2"
import TeamProfilePhoto from "./team-profile-photo"

import IconFacebook from "../icons/social-icon/facebook.svg"
import IconGithub from "../icons/social-icon/github.svg"
import IconInstagram from "../icons/social-icon/instagram.svg"
import IconLinkedin from "../icons/social-icon/linkedin.svg"
import IconTwitter from "../icons/social-icon/twitter.svg"
import IconDribbble from "../icons/social-icon/dribbble.svg"

const TitleH2Custom = styled(TitleH2)`
  font-size: 32px;
  margin-bottom: 0;

  &:after {
    content: none;
  }
`

const First = styled(Col)`
  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    border: 1px solid ${(props) => props.theme.color.primary};
    border-right: 1px solid transparent;
    border-radius: 24px 0 0 24px;
    padding: 60px 0 60px 10px;
    height: 18rem;
    align-content: center;
    display: grid;
  }
`

const Third = styled(Col)`
  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    border: 1px solid ${(props) => props.theme.color.primary};
    border-left: 1px solid transparent;
    border-radius: 0 24px 24px 0;
    padding: 60px 10px 60px 0;
    height: 18rem;
    align-content: center;
    display: grid;
  }
`

const PositionTextFunny = styled.div`
  display: none;
`

const PositionText = styled.div`
  transition: all 0.2s ease-in-out;
`

const Position = styled.div`
  transition: all 0.2s ease-in-out;
  position: relative;
  margin-bottom: 10px;

  &:hover ${PositionText} {
    display: none;
  }

  &:hover ${PositionTextFunny} {
    display: block;
  }
`

const SocialBox = styled.div`
  background: ${(props) => props.theme.color.primary};
  border-radius: 4px;
  padding: 12px;
  display: inline-block;

  svg {
    fill: ${(props) => props.theme.color.white};
  }
`

const icons = {
  facebook: IconFacebook,
  github: IconGithub,
  instagram: IconInstagram,
  linkedin: IconLinkedin,
  twitter: IconTwitter,
  dribbble: IconDribbble,
}

const Social = ({ profile, network }) => {
  const Icon = icons[network]
  return (
    <Link to={profile} title={network}>
      <SocialBox>
        <Icon height={28} width={28} />
      </SocialBox>
    </Link>
  )
}

const SocialWrapper = styled.div`
  a:not(:last-child) {
    margin-right: 10px;
  }
`

const TeamProfile = ({ profileImageTag, employee }) => (
  <PaddingWrapper>
    <Row className={"align-items-center"}>
      <First lg={3} className="text-center text-lg-right">
        <TitleH2Custom>
          {employee.name} {employee.surname}
        </TitleH2Custom>
        <Position>
          <PositionText>{employee.position}</PositionText>
          <PositionTextFunny>{employee.funny_position}</PositionTextFunny>
        </Position>
        {employee.social && (
          <SocialWrapper>
            {Object.entries(employee.social)
              .filter((social) => social[1] !== null)
              .map((social, i) => (
                <Social key={i} profile={social[1]} network={social[0]} />
              ))}
          </SocialWrapper>
        )}
      </First>

      <TeamProfilePhoto
        profileImageTag={profileImageTag}
        alt=""
        employee={employee}
      />

      <Third lg={3} className="text-center text-lg-left">
        <div
          dangerouslySetInnerHTML={{ __html: employee.profile_description }}
        />
      </Third>
    </Row>
  </PaddingWrapper>
)

export default TeamProfile
