import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import {
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from "reactstrap"

import IconLogo from "../icons/logo-icon.svg"
import IconContact from "../icons/mail-tran.svg"
import IconLogin from "../icons/user.svg"
import { CustomButton } from "./navbar"

const HeaderWrapper = styled.header`
  border-bottom: 1px solid ${(props) => props.theme.color.gray300};
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.08);

  .navbar-brand {
    @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
      padding-left: 2rem;
    }

    &__text {
      color: ${(props) => props.theme.color.primary};
      font-family: "Neo Sans Pro", sans-serif;
      font-size: 23px;
      font-weight: 500;
      vertical-align: middle;
    }

    svg,
    svg path {
      transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:focus {
      svg {
        transform: scale(1.05);
      }

      svg path {
        transition: all 0.2s ease-in-out;
        /* stylelint-disable-next-line declaration-no-important */
        fill: ${(props) => props.theme.color.primary} !important;
      }
    }
  }

  .nav-link__icon {
    margin-right: 10px;
  }

  .navbar-nav {
    .nav-item {
      .nav-link {
        &:hover,
        &:focus {
          color: ${(props) => props.theme.color.primary};
        }
      }
    }
  }

  @media (max-width: ${(props) => props.theme.sizes_max.lg}) {
    .navbar-nav {
      margin-top: 15px;

      .nav-item {
        border-top: 1px solid ${(props) => props.theme.color.gray300};

        .nav-link {
          padding: 10px 0;
        }
      }
    }
  }

  @media (min-width: ${(props) => props.theme.sizes_min.xl}) {
    .navbar-nav {
      .nav-item {
        .nav-link {
          padding: 10px 15px;
        }
      }
    }
  }

  @media (min-width: ${(props) => props.theme.sizes_min.xxl}) {
    .navbar-nav {
      .nav-item {
        .nav-link {
          padding: 10px 20px;
        }
      }
    }
  }

  .navbar-toggler {
    border-color: ${(props) => props.theme.color.primary};

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(232,119,34, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  }
`

type HeaderProps = {
  menu: React.FC
  logo: typeof IconLogo
  hasLogin: boolean
  contactForm?: React.FC
}

const Empty: React.FC = () => <div />

const Header: React.FC<HeaderProps> = ({
  contactForm,
  menu,
  logo,
  hasLogin = false,
}) => {
  const [isOpen, setOpen] = useState(false)
  const [isModal, setModal] = useState(false)
  const [isOpenLogin, setOpenLogin] = useState(false)

  const Menu = menu
  const hasContactForm = contactForm !== undefined
  const ContactForm = contactForm !== undefined ? contactForm : Empty
  const Logo = logo
  const toggleModal = () => setModal(!isModal)
  const toggleLogin = () => setOpenLogin(!isOpenLogin)

  return (
    <HeaderWrapper>
      <Navbar expand="lg" light>
        <NavbarBrand tag={Link} to="/">
          <span className="d-lg-none d-xl-block">
            <Logo />
          </span>
          <span className="d-none d-lg-block d-xl-none">
            <IconLogo width={39} />
          </span>
        </NavbarBrand>

        <div className={"d-flex"}>
          {hasLogin && (
            <CustomButton
              onClick={toggleLogin}
              outlineprimary
              className={"d-lg-none mr-2"}
            >
              <span className={"d-none d-sm-block"}>Přihlášení</span>
              <IconLogin width={24} className={"d-sm-none"} fill={"#e87722"} />
            </CustomButton>
          )}
          {hasContactForm && (
            <CustomButton
              to="/kontakt"
              outlineprimary
              className={"d-lg-none mr-2"}
            >
              <span className={"d-none d-sm-block"}>Kontakt</span>{" "}
              <IconContact
                width={24}
                className={"d-sm-none"}
                fill={"#e87722"}
              />
            </CustomButton>
          )}

          <NavbarToggler onClick={() => setOpen(!isOpen)} />
        </div>
        <Collapse isOpen={isOpen} navbar>
          <Menu />
        </Collapse>

        {hasLogin && (
          <CustomButton
            onClick={toggleLogin}
            className={"ml-lg-1 d-none d-lg-block"}
            outlineprimary
          >
            Přihlásit
          </CustomButton>
        )}
        {hasContactForm && (
          <CustomButton
            to="/kontakt"
            className={"ml-lg-1 d-none d-lg-block"}
            outlineprimary
          >
            Kontakt
          </CustomButton>
        )}
      </Navbar>

      <Modal isOpen={isModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Napište nám</ModalHeader>
        <ModalBody>{hasContactForm && <ContactForm />}</ModalBody>
      </Modal>
      <Modal isOpen={isOpenLogin} toggle={toggleLogin}>
        <ModalHeader toggle={toggleLogin}>Přihlášení</ModalHeader>
        <ModalBody>
          <ul>
            <li>
              <a href="https://webmail.igloonet.cz">Webmail</a>
            </li>
            <li>
              <a href="https://admin.igloonet.cz">Administrace</a>
            </li>
            <li>
              <a href="https://www.igloonet.cz/admin/auth/">
                Stará administrace
              </a>
            </li>
          </ul>
        </ModalBody>
      </Modal>
    </HeaderWrapper>
  )
}

export default Header
