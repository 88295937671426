import React from "react"
import styled from "styled-components"
import SquareBorder from "./square-border"

const SquareBorderPrimaryW = styled(SquareBorder)`
  background: ${(props) => props.theme.color.primary};

  &:after {
    border: 1px solid ${(props) => props.theme.color.primary};
  }
`

const SquareBorderPrimary = (props) => (
  <SquareBorderPrimaryW>{props.children}</SquareBorderPrimaryW>
)

export default SquareBorderPrimary
