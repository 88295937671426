import React from "react"
import styled from "styled-components"
import { Col } from "reactstrap"
import TitleH3 from "./title/title-h3"

const CodexCol = styled(Col)`
  padding: 0;
  border: 0 0 2px 0 solid ${(props) => props.theme.color.gray300};

  &:nth-child(odd) {
    border-width: 0 0 2px 0;
  }

  &:last-child {
    border-width: 0;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    &:nth-child(odd) {
      border-width: 0 1px 2px 0;
    }

    &:nth-last-child(2) {
      border-width: 0 1px 0 0;
    }
  }
`

const CodexRule = styled.div`
  padding: 20px 10px;

  @media (min-width: ${(props) => props.theme.sizes_min.sm}) {
    padding: 30px;
  }
`

const Number = styled.span`
  color: ${(props) => props.theme.color.primary};
  font-size: 1.5em;
  margin-right: 0.5em;
`

function CodexItem(props) {
  return (
    <CodexCol md={6}>
      <CodexRule>
        <TitleH3>
          {props.icon ? props.icon : <Number>{props.i}</Number>} {props.title}
        </TitleH3>
        <p>{props.children}</p>
      </CodexRule>
    </CodexCol>
  )
}

export default CodexItem
