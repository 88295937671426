/* stylelint-disable value-keyword-case, selector-no-qualifying-type */
import { createGlobalStyle } from "styled-components"

import NeoSansPro_medium_woff from "../font/NeoSansPro-Medium.woff"
import NeoSansPro_medium_woff2 from "../font/NeoSansPro-Medium.woff2"
import NeoSansPro_font_regular_woff from "../font/NeoSansPro-Regular.woff"
import NeoSansPro_font_regular_woff2 from "../font/NeoSansPro-Regular.woff2"

import Montserrat_regular_woff from "../font/montserrat-v15-latin-ext_latin-regular.woff"
import Montserrat_regular_woff2 from "../font/montserrat-v15-latin-ext_latin-regular.woff2"
import Montserrat_bold_woff from "../font/montserrat-v15-latin-ext_latin-700.woff"
import Montserrat_bold_woff2 from "../font/montserrat-v15-latin-ext_latin-700.woff2"

// boostrap styly potřebujeme i pro reactstrap
import "../sass/style.scss"

export const TitleUnderline = (): string => `
  margin-bottom: 70px;
  :after {
    position: absolute;
    left: 0;
    display: block;
    width: 7%;
    min-width: 40px;
    max-width: 65px;
    height: 3px;
    margin-top: 10px;
    bottom: -6px;
    background: #e87722;
    content: " ";
  }
`

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-display: fallback;
    font-family: "Neo Sans Pro";
    font-style: normal;
    font-weight: 400;
    src: url("${NeoSansPro_font_regular_woff2}") format("woff2"),
    url("${NeoSansPro_font_regular_woff}") format("woff");
  }

  @font-face {
    font-display: fallback;
    font-family: "Neo Sans Pro";
    font-style: normal;
    font-weight: 500;
    src: url("${NeoSansPro_medium_woff2}") format("woff2"),
    url("${NeoSansPro_medium_woff}") format("woff");
  }

  @font-face {
    font-display: fallback;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    src: url("${Montserrat_regular_woff2}") format("woff2"),
    url("${Montserrat_regular_woff}") format("woff");
  }

  @font-face {
    font-display: fallback;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    src: url("${Montserrat_bold_woff2}") format("woff2"),
    url("${Montserrat_bold_woff}") format("woff");
  }

  html,
  body {
    position: relative;
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.color.white};
  }

  body {
    color: ${(props) => props.theme.color.gray900};
    font-family: Montserrat, sans-serif;
    font-size: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    position: relative;
    color: ${(props) => props.theme.color.blue};
    font-family: "Neo Sans Pro", sans-serif;

    span {
      position: relative;
    }
  }

  h1,
  .h1 {
    display: inline-block;
    margin-bottom: 50px;
    font-size: 40px;
    text-align: center;

    &:after {
      bottom: -10px;
    }

    @media (min-width: ${(props) => props.theme.sizes_min.md}) {
      font-size: 50px;

      &:after {
        bottom: 0;
      }
    }
  }

  h2,
  .h2 {
    font-size: 32px;
    margin-bottom: 40px;

    @media (max-width: ${(props) => props.theme.sizes_max.sm}) {
      &:after {
        bottom: -10px;
      }
    }

    @media (min-width: ${(props) => props.theme.sizes_min.md}) {
      ${TitleUnderline};
    }
  }

  h3,
  .h3 {
    font-size: 20px;
  }

  a {
    transition: all 0.2s ease-in-out;
    color: ${(props) => props.theme.color.primary};

    &:hover,
    &:focus {
      color: ${(props) => props.theme.color.primary};
      text-decoration: underline;
    }
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  blockquote,
  .blockquote {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid ${(props) => props.theme.color.gray300};
    color: #383b3f;
    font-size: 16px;
    font-style: italic;
    line-height: 27px;

    &:before {
      content: "„";
    }

    &:after {
      content: "“";
    }
  }

  pre {
    padding: 30px 50px;
    margin-bottom: 20px;
    border: 1px solid ${(props) => props.theme.color.gray300};
    background-color: ${(props) => props.theme.color.gray200};
    font-size: 16px;

    &.tip {
      text-align: center;

      span:before,
      span:after {
        position: relative;
        top: 11px;
        display: inline-block;
        width: 17px;
        height: 3px;
        background-color: ${(props) => props.theme.color.green};
        content: " ";
      }

      span:before {
        left: -5px;
      }

      span:after {
        right: -5px;
      }
    }
  }

  table {
    width: 100%;
    margin-bottom: 20px;
    background-color: transparent;

    thead {
      th {
        padding: 15px 30px;
        background: ${(props) => props.theme.color.blue};
        color: ${(props) => props.theme.color.white};
      }
    }

    tbody {
      tr:nth-of-type(even) {
        background: ${(props) => props.theme.color.gray200};
      }

      td {
        padding: 15px 12px;
        border: 1px solid ${(props) => props.theme.color.gray300};
        font-size: 12px;
        font-weight: 500;
        text-align: center;
      }
    }
  }

`

export default GlobalStyle
