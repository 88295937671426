import React from "react"
import styled from "styled-components"
import { Container } from "reactstrap"

type ReferenceContainerProps = {
  alignClass?: string
}

const Wrapper = styled(Container)`
  max-width: 62.5rem;
  text-align: center;
  padding: 3rem 1rem;

  @media (max-width: ${(props) => props.theme.sizes_max.md}) {
    text-align: left;
    padding: 1rem;
  }

  p {
    text-align: left;
  }

  ul {
    padding: 0;
    margin-bottom: 15px;
    text-align: left;

    > li {
      padding: 0 0 1rem 2rem;
      margin: 0;
      list-style-type: none;
      text-indent: -1.5rem;

      &:before {
        display: inline-block;
        width: 1.5rem;
        padding-right: 0.5rem;
        color: ${(props) => props.theme.color.primary};
        content: "–";
        font-weight: 700;
        text-align: right;
      }
    }
  }
`

const ReferenceContainer: React.FC<ReferenceContainerProps> = ({
  alignClass,
  children,
}) => {
  return (
    <Wrapper className={"py-5 " + (alignClass ? alignClass : "text-center")}>
      {children}
    </Wrapper>
  )
}

export default ReferenceContainer
