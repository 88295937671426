import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { Col } from "reactstrap"

const ImageNavigation = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .division {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    margin: 0;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.xl}) {
    position: relative;
    top: 0;
    left: 0;
    width: 33.75rem;
    height: 33.75rem;
    box-sizing: border-box;
    text-align: center;

    .division {
      display: block;
      position: absolute;
      overflow: hidden;
      width: 50%;
      height: 50%;
      opacity: 0.25;

      &:hover,
      &.active {
        opacity: 1;
      }

      .circle {
        position: absolute;
        width: 160%;
        height: 160%;
        border-radius: 50%;
        border: 3px solid ${(props) => props.theme.color.primary};

        &:active {
          border: 3px solid ${(props) => props.theme.color.primary};
        }
      }

      .name {
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        color: ${(props) => props.theme.color.primary};
        font-weight: 600;
      }
    }

    .division-marketing {
      top: 0;
      left: 0;

      .marketing-quarter {
        top: 3.375rem;
        left: 3.375rem;
      }

      .marketing-name {
        transform: rotate(-41deg);
        top: 27%;
        left: 14%;
      }
    }

    .division-hosting {
      top: 0;
      left: 50%;

      .hosting-quarter {
        top: 3.375rem;
        left: -13.25rem;
      }

      .hosting-name {
        transform: rotate(43deg);
        top: 26%;
        left: 52%;
      }
    }

    .division-administrativa {
      top: 50%;
      left: 50%;

      .administrativa-quarter {
        top: -13.25rem;
        left: -13.25rem;
      }

      .administrativa-name {
        transform: rotate(-46deg);
        top: 65%;
        left: 39%;
      }
    }

    .division-vyvoj {
      top: 50%;
      left: 0;

      .vyvoj-quarter {
        top: -13.25rem;
        left: 3.375rem;
      }

      .vyvoj-name {
        transform: rotate(44deg);
        top: 67%;
        left: 20%;
      }
    }
  }
`

const ImageWrapper = styled.div`
  width: 17.5rem;
  height: 17.5rem;
  border: 1px solid ${(props) => props.theme.color.primary};
  border-radius: 50%;
  padding: 2.5rem;

  @media (min-width: ${(props) => props.theme.sizes_min.xl}) {
    border: none;
    border-radius: 100%;
  }
`

const ImageProperties = css`
  width: 12.5rem;
  height: 12.5rem;
  z-index: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${(props) => props.theme.sizes_min.xl}) {
    width: 21.875rem;
    height: 21.875rem;
    display: inline;
    position: absolute;
    top: 6.125rem;
    left: 6.125rem;
    z-index: 1;
    transition: 0.5s;
  }
`

const ImageNormal = styled.img`
  ${ImageProperties};
`

const ImageFunny = styled.img`
  ${ImageProperties};
  opacity: 0;
  position: absolute;
  top: 4.1rem;

  &:hover {
    opacity: 1;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.xl}) {
    opacity: 0;

    &:hover {
      opacity: 1;
      transition: 0.5s;
    }
  }
`

const DivisionLink = (props) => (
  <Link
    to={"/" + props.type + "/tym"}
    className={
      "division division-" +
      props.type +
      (props.active === props.type || props.active === props.type2
        ? " active"
        : "")
    }
  >
    <div className={"circle " + props.type + "-quarter"} />
    <div className={"name " + props.type + "-name"}>{props.children}</div>
  </Link>
)

const Division = (props) => (
  <>
    <DivisionLink type="marketing" active={props.active}>
      Marketing
    </DivisionLink>
    <DivisionLink type="hosting" active={props.active}>
      Hosting
    </DivisionLink>
    <DivisionLink
      type={"administrativa"}
      type2={"obchod"}
      active={props.active}
    >
      Administrativa
    </DivisionLink>
    <DivisionLink type="vyvoj" active={props.active}>
      Vývoj
    </DivisionLink>
  </>
)

export default function TeamProfilePhoto({ profileImageTag, alt, employee }) {
  const ProfileImage = profileImageTag

  return (
    <Col lg={6}>
      <ImageNavigation>
        <ImageWrapper>
          <ProfileImage
            imageTag={ImageNormal}
            alt={alt}
            filename={`team/profile/${employee.login}`}
          />
          <ProfileImage
            imageTag={ImageFunny}
            alt={alt}
            filename={`team/profile-funny/${employee.login}`}
          />
        </ImageWrapper>

        <Division active={employee.division} />
      </ImageNavigation>
    </Col>
  )
}
