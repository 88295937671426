import React, { FC } from "react"
import styled from "styled-components"
import { Col, Container, Row } from "reactstrap"

import FormComponent from "./form"
import TitleH2Center from "./title/title-h2-center"

const FormWrapper = styled.div`
  background: ${(props) => props.theme.color.gray200};
  padding: 3.5rem 0;
`

interface CFJProps {
  heading: string
  messagePlaceholder?: string
  buttonTitle?: string
  lang?: string
}

type tTranslations = {
  [key: string]: {
    [key: string]: string
  }
}

const t: tTranslations = {
  buttonTitle: {
    en: "I want to work in igloo",
    cs: "chci pracovat v igloo",
  },
  messagePlaceholder: {
    en: "Hit me up with something nice",
    cs: "Napiš mi něco hezkého",
  },
  consentText: {
    en: "By submitting the form you agree to the processing of your personal data",
    cs: "Odesláním formuláře souhlasíš se zpracováním svých osobních údajů",
  },
}

const InnerWrapper = styled(Container)`
  @media (min-width: ${(props) => props.theme.sizes_min.xl}) {
    width: 75%;
  }
`

const Consent = styled.div`
  font-size: 12px;
`

const ContactFormJobs: FC<CFJProps> = (props) => {
  const lang = props.lang ? props.lang : "cs"

  const buttonTitle = props.buttonTitle ?? t["buttonTitle"][lang]
  const messagePlaceholder =
    props.messagePlaceholder ?? t["messagePlaceholder"][lang]
  const consentText = props.consentText ?? t["consentText"][lang]

  const linkedinPlaceholder = lang === "en" ? "LinkedIn" : "Odkaz na LinkedIn"
  const cvPlaceholder = lang === "en" ? "Your CV" : "Tvůj životopis"

  return (
    <FormWrapper id={"form"}>
      <Container>
        <InnerWrapper>
          <TitleH2Center className="mb-5">{props.heading}</TitleH2Center>

          <Row>
            <Col>
              <>
                <FormComponent
                  buttonTitle={buttonTitle}
                  recipient="prace"
                  messagePlaceholder={messagePlaceholder}
                  emailPlaceholder={"E-mail"}
                  linkedinPlaceholder={linkedinPlaceholder}
                  cvPlaceholder={cvPlaceholder}
                  lang={lang}
                />
                <Consent className="text-center p-2">{consentText}</Consent>
              </>
            </Col>
          </Row>
        </InnerWrapper>
      </Container>
    </FormWrapper>
  )
}

export default ContactFormJobs
