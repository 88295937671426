import React from "react"
import styled from "styled-components"
import { Col, Row } from "reactstrap"
import SquareBorder from "../square-border/square-border"
import Link from "../link"

type ReferencesProps = {
  anotherReferences: Array<{
    href: string
    image: string
    alt: string
    name: string
  }>
}

const Title = styled.h6`
  color: ${(props) => props.theme.color.primary};
  font-size: 16px;
  @media (min-width: ${(props) => props.theme.sizes_min.sm}) {
    font-size: 18px;
  }
`

const ReferencesFull: React.FC<ReferencesProps> = ({ anotherReferences }) => {
  return (
    <Row className="justify-content-center text-center">
      {anotherReferences.map((ref, i) => (
        <Col key={i} lg={3} md={4} xs={6} className="mb-4">
          <SquareBorder className="mb-2" href={ref.href}>
            <img
              className="img-fluid"
              src={ref.image}
              alt={ref.alt}
              loading="lazy"
            />
          </SquareBorder>
          <Link to={ref.href}>
            <Title>{ref.name}</Title>
          </Link>
        </Col>
      ))}
    </Row>
  )
}

export default ReferencesFull
