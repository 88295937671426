import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { action } from "@storybook/addon-actions"

import Button, { ButtonStyle } from "./buttons/button"
import { Col, Container, Row } from "reactstrap"
import OrderDebug from "./order-debug"

const OrderNextStepInner = styled.div`
  padding: 45px 0;
  background: ${(props) => props.theme.color.gray100};

  .OrderNextStep {
    &__back {
      text-decoration: underline;
    }
  }
`

function orderPrice(order, vat = false) {
  let sum = 0
  if (order && order.data) {
    if (order.data.domains && order.data.domains.length) {
      sum += order.data.domains.reduce((s, i) => s + (i.price || 0), 0)
    }
    if (order.data.hostings && order.data.hostings.length) {
      sum += order.data.hostings.reduce((s, i) => s + (i.price || 0), 0)
    }
  }

  if (vat) {
    sum = sum * 1.21
  }

  return sum
  //return order.data.domains[0].price //.reduce((sum, i) => sum + i)
}

const OrderNextStep = (props) => (
  <OrderNextStepInner>
    <Container>
      <Row>
        <Col
          md="auto"
          className="align-self-center text-center text-md-left mb-5 mb-md-0"
        >
          <Link to="/webhosting" className="OrderNextStep__back">
            Zpět na výběr hostingu
          </Link>
        </Col>
        <Col className="d-sm-flex text-center align-items-center justify-content-center">
          <div className=" ml-md-auto text-center mb-3 mb-sm-0 text-md-right">
            <div>Celkem bez DPH {orderPrice(props.order)} Kč</div>
            <strong>
              Celkem k zaplacení {orderPrice(props.order, true)} Kč
            </strong>
          </div>

          {/*TODO zakazat button pro ostatni stranky if ({props.button}) {<div>sdsda</div>}*/}
          <Button
            className="ml-3"
            disabled={props.isSubmitting}
            onClick={action("clicked")}
            renderAs="button"
            style={ButtonStyle.Primary}
            type="submit"
          >
            Pokračovat k platbě
          </Button>
        </Col>
      </Row>
    </Container>

    <OrderDebug {...props} />
  </OrderNextStepInner>
)

export default OrderNextStep
