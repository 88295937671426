import React, { FC } from "react"
import Link from "./link"
import styled from "styled-components"
import { Col, Container, Row } from "reactstrap"

import FormComponent from "./form"
import TitleH2Center from "./title/title-h2-center"

import IconLinkedin from "../icons/social-icon/linkedin.svg"
import IconPhone from "../icons/phone-tran.svg"
import IconMail from "../icons/mail-tran.svg"

//TODO FIX ME melo by byt v icons
import Arrow from "../images/arrow-orange-r.svg"

const Name = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  padding-top: 8px;
`

const FormWrapper = styled.div`
  background: ${(props) => props.theme.color.gray200};
  padding: 3.5rem 0;
`

//TODO FIX ME melo by byt v icons
const ContactBoxLink = styled(Link)`
  align-items: center;
  display: flex;
  margin-bottom: 15px;
  border: 1px solid transparent;
  padding: 16px 50px 16px 20px;
  background: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.white};
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  &:before {
    mask: url(${Arrow}) center no-repeat;
    mask-size: cover;
    background-color: ${(props) => props.theme.color.white};
    content: "";
    position: absolute;
    line-height: 3.5;
    color: #fff;
    transition: all 0.2s ease-in-out;
    width: 10px;
    height: 14px;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
  }

  svg {
    margin-right: 25px;
    fill: ${(props) => props.theme.color.white};
  }

  &:hover,
  &:focus {
    color: ${(props) => props.theme.color.primary};
    background-color: ${(props) => props.theme.color.white};
    border: 1px solid ${(props) => props.theme.color.primary};
    text-decoration: none;

    svg {
      fill: ${(props) => props.theme.color.primary};
    }

    &:before {
      background-color: ${(props) => props.theme.color.primary};
      right: 20px;
    }
  }
`

const ContactBoxWrapper = styled.div`
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${(props) => props.theme.color.gray300};

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    border: none;
    margin: 0;
    padding: 0;
  }

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid ${(props) => props.theme.color.gray300};
  }
`

const ContactBox = (props) => (
  <Col xs={12}>
    <ContactBoxLink to={props.to}>
      {props.icon}
      <div>
        <b>{props.name}</b>
        <div>{props.desc}</div>
      </div>
    </ContactBoxLink>
  </Col>
)

type GCFProps = {
  employees: Employee[]
  ProfileImage: React.FC<ProfileImageProps>
  contact: string
  modal?: boolean
  heading?: string
  subheading?: string
  buttonTitle: string
  buttonSubtitle: string
}
const GenericContactForm: React.FC<GCFProps> = ({
  employees,
  ProfileImage,
  contact,
  modal = false,
  heading,
  subheading,
  buttonTitle,
  buttonSubtitle,
}) => {
  const employee = employees.find((e: { login: string }) => {
    return e.login === contact
  })

  // in case we find no one
  if (employee === undefined) {
    return null
  }

  const getAttr = (attr: string, default_value: string | number | boolean) => {
    if (
      employee.contact_form !== undefined &&
      // check for NULL (empty)
      employee.contact_form &&
      employee.contact_form[attr]
    ) {
      return employee.contact_form[attr]
    } else {
      return default_value ? default_value : ""
    }
  }

  const Image = () => (
    <ProfileImage
      filename={`team/profile/${employee.login}`}
      alt={`Profilovka ${employee.name} ${employee.surname}`}
      className="img-fluid"
    />
  )

  const formatted_phone = String(employee.telephone).replace(/(.{3})/g, "$1 ")

  return (
    <BottomContactForm
      recipient="igloonet"
      modal={modal}
      image={Image}
      heading={
        heading
          ? heading
          : getAttr("heading", "Pojďme probrat, jak pomůžeme vašemu projektu")
      }
      subheading={
        subheading
          ? subheading
          : getAttr(
              "subheading",
              "Přijedeme za vámi nebo se můžeme potkat u nás v Brně, Praze či Plzni"
            )
      }
      box1Desc={getAttr("box1_desc", "Spojme se přes LinkedIn.")}
      box1Link={getAttr("box1_link", employee.social.linkedin)}
      box1Name={getAttr("box1_name", `${employee.name} ${employee.surname}`)}
      box1Disable={getAttr("box1_disable", false)}
      box2Desc={getAttr("box2_desc", "Zavolejme si, nejlépe 14–17.")}
      box2Link={getAttr("box2_link", `tel:${employee.telephone}`)}
      box2Name={getAttr("box2_name", formatted_phone)}
      box3Desc={getAttr("box3_desc", "Odepíšu do druhého dne.")}
      box3Link={getAttr("box3_link", `mailto:${employee.mail}`)}
      box3Name={getAttr("box3_name", employee.mail)}
      buttonTitle={
        buttonTitle ? buttonTitle : getAttr("buttonTitle", "Chci konzultaci")
      }
      buttonSubtitle={
        buttonSubtitle
          ? buttonSubtitle
          : getAttr("buttonSubtitle", "konzultace je ZDARMA")
      }
    />
  )
}

interface BCFProps {
  recipient: string
  modal: boolean
  image: string
  heading: string
  subheading: string
  box1Desc: string
  box1Link: string
  box1Name: string
  box1Disable: boolean
  box2Desc: string
  box2Link: string
  box2Name: string
  box2Disable: boolean
  box3Desc: string
  box3Link: string
  box3Name: string
  box3Disable: boolean
  buttonTitle: string
  buttonSubtitle: string
}

const BottomContactForm: FC<BCFProps> = (props) => {
  const Image = props.image
  const Wrapper = props.modal ? `div` : FormWrapper
  const Consent = styled.div`
    font-size: 12px;
  `

  return (
    <Wrapper id={"form"}>
      <Container>
        <TitleH2Center className="mb-4">{props.heading}</TitleH2Center>
        <div className="text-center mb-5">{props.subheading}</div>

        <Row>
          <Col md={props.modal ? 12 : 7}>
            <ContactBoxWrapper>
              <Row>
                {!props.box1Disable && (
                  <ContactBox
                    icon={<IconLinkedin height={28} width={28} />}
                    name={props.box1Name}
                    desc={props.box1Desc}
                    to={props.box1Link}
                  />
                )}

                <ContactBox
                  icon={<IconPhone height={28} width={28} />}
                  name={props.box2Name}
                  desc={props.box2Desc}
                  to={props.box2Link}
                />

                <ContactBox
                  icon={<IconMail height={28} width={28} />}
                  name={props.box3Name}
                  desc={props.box3Desc}
                  to={props.box3Link}
                />
              </Row>
            </ContactBoxWrapper>

            <div className={"d-none d-lg-block"}>
              <FormComponent
                buttonTitle={props.buttonTitle}
                buttonSubtitle={props.buttonSubtitle}
                recipient={props.recipient}
                messagePlaceholder={"Nebo mi rovnou napište tady."}
                contactPlaceholder={
                  "Kam se vám mám ozvat? E-mail, telefon nebo LinkedIn?"
                }
              />
              <Consent className="text-center p-2">
                Odesláním formuláře souhlasíte se zpracováním osobních údajů.
              </Consent>
            </div>
          </Col>

          {props.modal ? null : (
            <Col md={5}>
              <Image />
              <Name>{props.box1Name}</Name>
            </Col>
          )}
        </Row>
      </Container>
    </Wrapper>
  )
}

export default GenericContactForm
