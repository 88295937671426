import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "reactstrap"

import BenefitParking from "../../images/benefits/benefit-parking.png"
import BenefitFood from "../../images/benefits/benefit-food.png"
import BenefitCoffe from "../../images/benefits/benefit-coffee.png"
import BenefitEquipment from "../../images/benefits/benefit-equipment.png"
import BenefitTelephone from "../../images/benefits/benefit-telephone.png"
import BenefitSickday from "../../images/benefits/benefit-sickday.png"
import BenefitSelfStudy from "../../images/benefits/benefit-selfstudy.png"
import BenefitMealTicket from "../../images/benefits/benefit-meal-ticket.png"
import BenefitEnglish from "../../images/benefits/benefit-english.png"
import BenefitQuiet from "../../images/benefits/benefit-quiet.png"
import BenefitMultisport from "../../images/benefits/benefit-multisport.png"
import { PaddingWrapper, TitleH2Center } from "@igloonet-web/shared-ui"

type BenefitsProps = {
  data: Array<{
    image: string
    title: string
    text: string
  }>
}

const BenefitWrapper = styled(Col)`
  margin-bottom: 40px;
`

const BenefitTitle = styled.h3``

const BenefitText = styled.div`
  font-size: 14px;
`

const BenefitImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  margin: 0 auto 15px;
  border: 2px solid ${(props) => props.theme.color.primary};
  border-radius: 100%;
`

const BenefitImage = styled.img`
  height: 70px;
  width: auto;
`
const benefitList = [
  {
    title: "Plná lednice",
    text: "Už nikdy nebudeš hladem. Ale pozor, piškotíky jdou na dračku.",
    image: BenefitFood,
  },
  {
    title: "Skvělá káva",
    text: "Sraz u kávovaru je náš rituál. Milovníci espressa i filtru si přijdou na své.",
    image: BenefitCoffe,
  },
  {
    title: "Vybavení",
    text: "Čekej dva monitory, pořádnou židli, notes a sdílej polohovací stůl.",
    image: BenefitEquipment,
  },
  {
    title: "Parkování",
    text: "Zaparkuj v našem areálu jen pár kroků od své židle v kanclu.",
    image: BenefitParking,
  },
  {
    title: "Sickdays",
    text: "Nepřijdeš do práce a my se tě nebudeme ptát proč.",
    image: BenefitSickday,
  },
  {
    title: "Firemní tarif",
    text: "Po roce smartfoun s neomezenkou. Nebo hned, když ho potřebuješ pro práci.",
    image: BenefitTelephone,
  },
  {
    title: "Sebevzdělávání",
    text: "Osm hodin měsíčně z pracovní doby na sebevzdělávání.",
    image: BenefitSelfStudy,
  },
  {
    title: "Stravenky",
    text: "Žádný zbytečný papír, každý měsíc rovnou na účet.",
    image: BenefitMealTicket,
  },
  {
    title: "MultiSport karta",
    text: "Sportuj až 365 dní v roce, my ti přidáme půlku.",
    image: BenefitMultisport,
  },
  {
    title: "Tichá místnost",
    text: "Využij klid na soustředění a hluk nech za dveřmi.",
    image: BenefitQuiet,
  },
  {
    title: "Angličtina",
    text: "Najdi si školu nebo lektora a my ti polovinu přispějeme.",
    image: BenefitEnglish,
  },
]

const Benefits: React.FC<BenefitsProps> = () => (
  <Container>
    <PaddingWrapper>
      <TitleH2Center>Nabízíme jedinečný balíček benefitů</TitleH2Center>
      <Row className="justify-content-center text-center">
        {benefitList.map((item, index) => (
          <BenefitWrapper key={index} sm={6} md={4} lg={3}>
            <BenefitImageWrapper>
              <BenefitImage
                src={item.image}
                height="60"
                width="60"
                loading="lazy"
              />
            </BenefitImageWrapper>
            <BenefitTitle>{item.title}</BenefitTitle>
            <BenefitText>{item.text}</BenefitText>
          </BenefitWrapper>
        ))}
      </Row>
      <div className="text-center">
        <strong>Nenašel jsi tu benefit, který jsi hledal?</strong>{" "}
        <a href="#kontakt">Napiš nám</a>, třeba jej vnímáme jen jako nezbytný
        standard a zapomněli jsme ho uvést.
      </div>
    </PaddingWrapper>
  </Container>
)

export default Benefits
