import React from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"

import Corner from "../images/corner.svg"

const EdgeBorderW = styled(Col)`
  border-style: solid;
  border-width: 1em;
  border-image-source: url(${Corner});
  border-image-slice: 75;
  border-image-width: auto;

  margin-bottom: 40px;
  margin-top: 10px;

  h2 {
    margin-bottom: 50px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  .EdgeBorder__arrow {
    margin: auto 0;
    padding-left: 20px;
    border-left: 1px solid ${(props) => props.theme.color.gray300};

    &:after,
    &:before {
      position: absolute;
      top: 50%;
      left: -1px;
      width: 0;
      height: 0;
      border: solid transparent;
      content: " ";
      pointer-events: none;
    }

    &:after {
      margin-top: -8px;
      border-width: 8px;
      border-color: rgba(255, 255, 255, 0);
      border-left-color: ${(props) => props.theme.color.white};
    }

    &:before {
      margin-top: -9px;
      border-width: 9px;
      border-color: rgba(194, 225, 245, 0);
      border-left-color: ${(props) => props.theme.color.gray300};
    }
  }

  @media (max-width: ${(props) => props.theme.sizes_max.lg}) {
    h2 {
      margin-bottom: 25px;
      font-size: 28px;
    }
  }

  @media (max-width: ${(props) => props.theme.sizes_max.md}) {
    border-image-outset: 10px;
    .EdgeBorder__arrow {
      margin-top: 30px;
      border-left: none;
    }

    img {
      display: block;
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
    }
  }
`

const EdgeBorderContent = styled.div`
  padding: 25px;

  @media (max-width: ${(props) => props.theme.sizes_max.lg}) {
    padding: 20px;
  }
  @media (max-width: ${(props) => props.theme.sizes_max.md}) {
    padding: 20px 0;
  }
`

type EdgeBorderSingleProps = TitleProp & {
  md: number
}

const EdgeBorderSingle: React.FC<EdgeBorderSingleProps> = (props) => (
  <EdgeBorder>
    <div className="EdgeBorderContent">
      <h2>{props.title}</h2>
      {props.children}
    </div>
  </EdgeBorder>
)

const EdgeBordersW = styled(Row)`
  margin-left: -1em;
  margin-right: -1em;

  ${EdgeBorderW} {
    margin-left: 1em;
    margin-right: 1em;
  }
`

const EdgeBorders: React.FC<ChildrenProp> = ({ children }) => (
  <EdgeBordersW>{children}</EdgeBordersW>
)

const EdgeBorder: React.FC<ChildrenProp> = ({ children, ...other }) => (
  <EdgeBorderW {...other}>
    <EdgeBorderContent>{children}</EdgeBorderContent>
  </EdgeBorderW>
)

export { EdgeBorderContent, EdgeBorders, EdgeBorderSingle }
export default EdgeBorder
