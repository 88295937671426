import React from "react"
import styled from "styled-components"

const Item = styled.li`
  position: relative;
  line-height: 2.2;
  color: ${(props) => props.theme.color.blue};

  &:before,
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    content: "";
    display: block;
    border-radius: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }

  &:before {
    left: -34px;
    width: 14px;
    height: 14px;
    background: ${(props) => props.theme.color.gray200};
  }

  &:after {
    left: -31px;
    width: 8px;
    height: 8px;
    background: ${(props) => props.theme.color.primary};
  }
`

const VerticalLineListItem = (props) => <Item>{props.children}</Item>

export default VerticalLineListItem
