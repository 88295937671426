import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Col, Row } from "reactstrap"
import TitleH2Center from "./title/title-h2-center"

const TeamCollectionWrapper = styled.div`
  padding: 50px 0;
`

const PhotoWrapper = styled(Col)`
  align-items: center;
  text-align: center;
  margin-bottom: 30px;

  a {
    text-decoration: none;
  }

  img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    filter: grayscale(100%);
    transition: all 0.2s ease-in-out;

    &:hover {
      filter: none;
    }
  }

  @media (max-width: ${(props) => props.theme.sizes_max.sm}) {
    img {
      width: 100px;
      height: 100px;
    }
  }
`

const Name = styled.h6`
  @media (max-width: ${(props) => props.theme.sizes_max.sm}) {
    font-size: 14px;
  }
`

const TeamCollection = (props) => {
  const ImageTag = props.imageTag

  return (
    <TeamCollectionWrapper>
      {props.title && <TitleH2Center>{props.title}</TitleH2Center>}
      <Row className="justify-content-md-center">
        {props.teamMembers
          .sort((a, b) => a.surname.localeCompare(b.surname))
          .map((member, i) => {
            return (
              <PhotoWrapper key={i} xs={6} sm={4} md={3} xl={2}>
                <Link to={"/" + member.login}>
                  <ImageTag
                    className="img-fluid mb-2"
                    alt={member.login}
                    loading="lazy"
                    filename={"team/mini/" + member.login + ".jpg"}
                  />
                  <Name>
                    {member.name} {member.surname}
                  </Name>
                </Link>
              </PhotoWrapper>
            )
          })}
      </Row>
    </TeamCollectionWrapper>
  )
}

export default TeamCollection
