import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Col, Container, Row } from "reactstrap"
import { TitleH2Center } from "../index"

const TextBoxImageTopWrapper = styled.div`
  padding: 50px 0;
  border-top: 1px solid ${(props) => props.theme.color.gray300};
  background: ${(props) => props.theme.color.gray200};
`

const LinkCustom = styled(Link)`
  color: initial;

  &:hover,
  &:focus {
    text-decoration: none;
    color: initial;

    h3 {
      color: ${(props) => props.theme.color.primary};
    }
  }
`

const ItemInner = (props) => (
  <>
    {props.image}
    <h3 className={"mt-4"}>{props.title}</h3>
    <p>{props.text}</p>
  </>
)

function Item(props) {
  return (
    <>
      {props.href ? (
        <LinkCustom to={props.href}>
          <ItemInner {...props} />
        </LinkCustom>
      ) : (
        <ItemInner {...props} />
      )}
    </>
  )
}

const TextBoxImageTop = (props) => (
  <TextBoxImageTopWrapper>
    <Container className="text-center">
      <TitleH2Center className={"mb-5"}>{props.title}</TitleH2Center>

      <Row>
        {props.data.map((item, index) => {
          return (
            <Col key={index} md={4}>
              <Item
                image={item.image}
                title={item.title}
                text={item.text}
                href={item.href}
              />
            </Col>
          )
        })}
      </Row>
    </Container>
  </TextBoxImageTopWrapper>
)

export default TextBoxImageTop
